// USER MENU
function openUserMenu() {
    if ($(".user-menu").css("display") !== "block") {
        $(".user-menu").show();
        if ($("html").hasClass("smaller-than-tablet")) {
            disablePageScroll();
        }
        // add function to close container on click outside
        // setTimeout needed or close function receives button click
        setTimeout(function() {
            $(document).on('click.close-usermenu', function(){
                closeUserMenu();
                if ($("html").hasClass("smaller-than-tablet")) {
                    reenablePageScroll();
                }
            });
        },10);
    } else {
        $(".js-user-menu-toggle").blur();
    }
}

function closeUserMenu() {
    $(".user-menu").slideUp(200);
    $(".js-user-menu-toggle").removeClass("active");
    $(document).off('click.close-usermenu');
}

/*
	// button event for activating light theme
	$(document).on('click',".light-theme-btn", function() {
		themeChange();
	});

	// button event for activating dark theme
	$(document).on('click',".dark-theme-btn", function() {
		themeChange();
	});

	function themeChange() {
		if ($("html").hasClass("light")) {
			$("html").removeClass("light");
			$("html").addClass("dark");
			$.ajax({
	            type: "POST",
	            url: "confsaver",
	            data: "theme=dark",
	            success: function(data){
	            	// window.location = "kartensatz-" + boxId;
	            }
	        });
		} else {
			$("html").removeClass("dark");
			$("html").addClass("light");
			$.ajax({
	            type: "POST",
	            url: "confsaver",
	            data: "theme=light",
	            success: function(data){
	            	// window.location = "kartensatz-" + boxId;
	            }
	        });
		}

		$(".user-menu").hide();
		// update donut charts
			// clear charts
			$(".donut-chart").each(function() {
				clearChart($(this));
			});
			// redraw charts
			setColors();
			$(".donut-chart").each(function() {
				createDonutChart($(this));
			});
	}
*/

function setProPurchaseTrigger(val) {
    if (getCookie("enableAllCookies") == "a") {
        setCookie("proPurchaseTrigger", val, 9999);
    }
}

function markNotificationsRed() {
	$(".js-notifications-toggle").addClass("new");
	$(".js-notifications-toggle").find(".fa-bell").addClass("new");
}

function markNotificationsNormal() {
	$(".js-notifications-toggle").removeClass("new");
	$(".js-notifications-toggle").find(".fa-bell").removeClass("new");
}

function createUserCheckAll(userId) {
	for (var i = 0; i < document.newUserForm.elements.length; i++) {
		var e = document.newUserForm.elements[i];
		if ((e.type == 'checkbox') && (e.name.endsWith('_' + userId))) {
			e.checked = true;
		}
	}
}



function showUploadAvatarInput(el) {
	$(el).hide();
	$('#uploadAvatarForm').show();
}


function uploadAvatarRunner(el) {
	var file = el.files[0];

    try {
        if (file.name.length < 1) {
            flashModal(i18n.gettext("The file does not have a readable name."));
        }
        else if (file.size > 10000000) {
            flashModal(i18n.gettext("The file is too large."));
        }
        else if (!(file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.gif'))) {
			flashModal(i18n.gettext("File type is not png, jpg or gif."));
		}
        else {
            // var file = $('input#uploadAvatarField')[0].files[0];
            flashModal(i18n.gettext("File uploaded."));
            var fd = new FormData(document.getElementById("uploadAvatarForm"));

            $.ajax({
                url: 'profile',
                type: 'POST',
                xhr: function () {  // custom xhr
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) { // if upload property exists
                        // myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // progressbar
                    }
                    return myXhr;
                },
                //Ajax events
                success: completeHandler = function (data) {

                    // workaround for chrome browser // delete the fakepath

                    if (navigator.userAgent.indexOf('Chrome')) {
                        var catchFile = $(":file").val().replace(/C:\\fakepath\\/i, '');
                    }
                    else {
                        var catchFile = $(":file").val();
                    }

                    // refresh profile

                    loadPageToCenter('profile');

                },
                error: errorHandler = function () {
                    flashModal(i18n.gettext("Error while processing the upload."));
                },
                // Form data
                data: fd,
                //Options to tell JQuery not to process data or worry about content-type
                cache: false,
                contentType: false,
                processData: false
            }, 'json');

        }
    } catch (e) {
        debug("Error while uploading picture");
        shortInfo(i18n.gettext("Error while uploading the picture."));
    }
}



function registerCouponDirectlyWithLogin(couponcode) {
    if ($("html").data("loggedin") == 1) {
        registerCouponDirectly(couponcode);
    } else {
        loadPageByAjax('register_or_login?couponcode=' + couponcode);
    }
}


// FRIENDSHIP

function befriendUser() {
	var userId = $("#userId").val();
	flashModal(i18n.gettext('Sending friendship request...'));
	$.ajax({
		type: "POST",
		url: "notifications",
		data: "requestFriendship=" + userId,
		success: function (data) {
			// on ajax success:
			flashModal(data);
			$(".js-befriend-btn").removeClass("visible").addClass("hidden");
			$(".js-cancel-friend-request-btn").removeClass("hidden").addClass("visible");
		}
	});
}

function cancelFriendRequest() {
	var userId = $("#userId").val();
	flashModal(i18n.gettext('Retracting friendship request...'));
	$.ajax({
		type: "POST",
		url: "notifications",
		data: "cancelFriendshipRequest=" + userId,
		success: function (data) {
			// on ajax success:
			flashModal(data);
			$(".js-cancel-friend-request-btn").removeClass("visible").addClass("hidden");
			$(".js-befriend-btn").removeClass("hidden").addClass("visible");
		}
	});
}

function unfriendUser() {
	var userId = $("#userId").val();
	flashModal(i18n.gettext('Retracting friendship...'));
	$.ajax({
		type: "POST",
		url: "notifications",
		data: "deleteFriendship=" + userId,
		success: function (data) {
			// on ajax success:
			flashModal(data);
			$(".js-unfriend-btn").removeClass("visible").addClass("hidden");
			$(".js-befriend-btn").removeClass("hidden").addClass("visible");
		}
	});
}
