function selectSubCourse() {
    var courseId = $('#activeCourse').val();
    var url = $('#pagehash').val();
    var parts = url.split("-");
    var loadUrl = parts[0] + "-";
    if (isNaN(parseFloat(parts[1]))) {
        loadUrl += parts[1] + "-";
    }
    loadPageByAjax(loadUrl + courseId);
}

function createSubCourse() {
    var newCourseName = $("#newCourseName").val();
    var newAcademyName = '';
    var newAcademyCity = '';
    var enableFinding = $("#enableFinding").is(":checked");
    var enablePupilsToAddCardsets = $("#enablePupilsToAddCardsets").is(":checked");
    var makeCardsetsMandatory = $("#makeCardsetsMandatory").val();
    var allowMembersToInvite = $("#allowMembersToInvite").is(":checked");
    if ($("#newAcademyName").length > 0) {
        newAcademyName = $("#newAcademyName").val();
    }
    if ($("#newAcademyCity").length > 0) {
        newAcademyCity = $("#newAcademyCity").val();
    }
    
    if (newCourseName.trim() == "") {
        flashModal(i18n.gettext("Name must not be empty!"));
    } else {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-new",
            data: "newCourseName=" + newCourseName
                    + "&newAcademyName=" + newAcademyName
                    + "&newAcademyCity=" + newAcademyCity
                    + "&enableFinding=" + enableFinding
                    + "&enablePupilsToAddCardsets=" + enablePupilsToAddCardsets
                    + "&makeCardsetsMandatory=" + makeCardsetsMandatory
                    + "&allowMembersToInvite=" + allowMembersToInvite,
            success: function (data) {
                var obj = JSON.parse(data.trim());
                flashModal(obj.message);
                if (obj.success) {
                    loadPageByAjax('course-' + obj.courseId);
                }
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the username."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    }
}

function deleteSubCourse(elem) {
    var courseId = $(elem).data('id');
    if (confirm(i18n.gettext('Really delete the course? The accounts would remain. Course-cardsets would be removed from attendees.'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "courses",
            data: "deleteCourse=" + courseId,
            success: function (data) {
                flashModal(data);
                loadPageToCenter('courses');
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the course ID."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    } else {
        return false;
    }
}

function changeInvitationMailAddress(elem) {
    var courseId = $("#courseid").val();
    var defaultMailAddress = $(elem).data("mail");
    var invitationId = $(elem).data("invitationid");
    var mailAddress = prompt(i18n.gettext("Please enter a new mail address:"), defaultMailAddress);
    if (mailAddress !== null) {
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "changeMailAddress=" + mailAddress + "&invitationId=" + invitationId,
            success: function (data) {
                flashModal(data);
                loadInvitationsOfCourse();
            }
        });
    }
}

function courseAreaChange(el) {
    var url = 'areaChanger';
    url += '?user=' + $(el).data('user');
    url += '&course=' + $(el).data('course');
    url += '&area=' + $(el).data('area');
    url += '&selected=' + $(el).val();

    debug($(el).val());
    $(el).fadeTo(1000, 0.2);
    if ($(el).val() == "no") {
        $(el).css('background-color', 'lightpink');
    } else {
        $(el).css('background-color', 'lightgreen');
    }

    $.ajax({
        type: "GET",
        url: url,
        success: function (data) {
            $(el).fadeTo(1000, 1.0);
        }
    });
}

function courseInvitationAreaChange(elem) {
    var url = 'areaChanger';
    url += '?id=' + $(el).data('id');
    url += '&course=' + $(el).data('course');
    url += '&area=' + $(el).data('area');
    url += '&selected=' + $(el).val();

    debug($(el).val());
    $(el).fadeTo(1000, 0.2);
    if ($(el).val() == "no") {
        $(el).css('background-color', 'lightpink');
    } else {
        $(el).css('background-color', 'lightgreen');
    }

    $.ajax({
        type: "GET",
        url: url,
        success: function (data) {
            $(el).fadeTo(1000, 1.0);
        }
    });
}

function courseInvitationAction(elem) {
    debug("Invitation action is: " + elem.value);
    if (elem.value == 'delete') {
        deleteInvitationFromCourse(elem);
    } else if (elem.value == 'sendInvitation') {
        sendCourseInvitation(elem);
    } else if (elem.value == 'makeLeader') {
        makeInvitationCourseLeader(elem);
    } else if (elem.value == 'degradeLeader') {
        degradeInvitationCourseLeader(elem);
    }
}

function sendCourseInvitation(elem) {
    var invitationId = $(elem).data('id');
    var courseId = $("#courseid").val();
    if (confirm(i18n.gettext('Really re-send invitation?'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "sendInvitation=" + invitationId,
            success: function (data) {
                flashModal(data, 4000);
                loadInvitationsOfCourse();
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the invitation ID."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    } else {
        return false;
    }
}

function deleteUserFromCourse(elem) {
    var userId = $(elem).data('id');
    var courseId = $("#courseid").val();
    // debug("Delete user " + userId);
    if (confirm(i18n.gettext('Really sign out from course? Course-cardsets would be removed from the account.'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "deleteUserFromCourse=" + userId,
            success: function (data) {
                flashModal(data);
                refreshPage();
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the user ID."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    } else {
        return false;
    }

}

function makeCourseLeader(elem) {
    var userId = $(elem).data('id');
    var courseId = $("#courseid").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-users-" + courseId,
        data: "makeLeader=" + userId,
        success: function (data) {
            flashModal(data);
            loadUsersOfCourse();
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the user ID."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function degradeCourseLeader(elem) {
    var userId = $(elem).data('id');
    var courseId = $("#courseid").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-users-" + courseId,
        data: "degradeLeader=" + userId,
        success: function (data) {
            flashModal(data);
            loadUsersOfCourse();
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the user ID."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function sendCourseUserData(elem) {
    var userId = $(elem).data('id');
    var courseId = $("#courseid").val();
    if (confirm(i18n.gettext('Really re-send user data?'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "sendCourseUserData=" + userId,
            success: function (data) {
                flashModal(data, 4000);
                loadUsersOfCourse();
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the user ID."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    } else {
        return false;
    }
}

function createCourseUsers() {

    flashModal(i18n.gettext("Please wait..."));

    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    var formData = {};
    var i = 0;

    $(".newUserDataset").each(function () {
        var dataset = {};
        $(this).find("input,select").each(function () {
            if (this.type == "checkbox") {
                dataset[this.name] = this.checked;
            } else {
                // if (this.name.startsWith('salutation') || this.name.startsWith('firstName') || this.name.startsWith('lastName') || this.name.startsWith('mailAddress')) {
                dataset[this.name] = this.value;
                // }
            }
        });
        if (dataset.mailAddress != "") {
            formData[i] = dataset;
            i++;
            debug("Adding dataset " + i);
        }
    });

    if ($('input[name="proDay"]').length > 0) {
        var proDay = $('input[name="proDay"]').val();
        var proMonth = $('input[name="proMonth"]').val();
        var proYear = $('input[name="proYear"]').val();
    }
    if ($('input[name="accountInactiveWithoutPro"]').length > 0) {
        var accountInactiveWithoutPro = $('input[name="accountInactiveWithoutPro"]').is(":checked");
    }
    
    $("#createButton").hide();

    $.ajax({
        type: "POST",
        url: url,
        data: "inviteUsersData=" + JSON.stringify(formData) + "&proDay=" + proDay + "&proMonth=" + proMonth + "&proYear=" + proYear + "&accountInactiveWithoutPro=" + accountInactiveWithoutPro + "&courseId=" + courseId,
        success: function (data) {
            flashModal(data);
            refreshPage();
//            $("#createButton").show();
//            $("#courseUsersMessages").show();
//            $("#courseUsersMessages").html(data);
//            // loadUsersOfCourse();
//            $("html, body").animate({scrollTop: 0}, "slow");
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the user data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function spreadBoxToCourseOriginal(elem) {
    var boxId = $("#spreadBoxId").val();
    var courseId = $("#courseid").val();
    var addon = '';
    if ($("#spreadForm").serialize() != 'undefined') {
        addon = "&" + $("#spreadForm").serialize();
    }
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-cardboxes-" + courseId,
        data: 'spreadBoxOriginal=' + boxId + addon,
        success: function (data) {
            flashModal(data);
            // loadBoxesOfCourse();
            refreshPage();
            $("html, body").animate({scrollTop: 0}, "slow");
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the cardset data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function spreadBoxToCourseCopy(elem) {
    var boxId = $("#spreadBoxId").val();
    var courseId = $("#courseid").val();
    var addon = '';
    if ($("#spreadForm").serialize() != 'undefined') {
        addon = "&" + $("#spreadForm").serialize();
    }
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-cardboxes-" + courseId,
        data: 'spreadBoxCopy=' + boxId + addon,
        success: function (data) {
            flashModal(data);
            // loadBoxesOfCourse();
            refreshPage();
            $("html, body").animate({scrollTop: 0}, "slow");
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the cardset data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function spreadBoxToCourse() {
    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    var addon = '';
    if ($("#spreadForm").serialize() != 'undefined') {
        addon = "&" + $("#spreadForm").serialize();
    }
    $('#center').fadeTo(200, 0.75, 'swing');
    flashModal(i18n.gettext('Assigning cardsets...'));
    $.ajax({
        type: "POST",
        url: url,
        data: 'do=spreadBox' + addon + "&courseId=" + courseId,
        success: function (data) {
            flashModal(data);
            // loadBoxesOfCourse();
            refreshPage();
            $("html, body").animate({scrollTop: 0}, "slow");
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the cardset data."));
        },
        complete: function () {
            $('#center').fadeTo(200, 1, 'swing');
        }
    });
}

function deleteBoxFromCourse(elem) {
    var boxId = $(elem).data('id');
    var courseId = $("#courseid").val();
    if (confirm(i18n.gettext('Really remove the cardset?') + " " + i18n.gettext("It will be removed from the accounts of all members."))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-cardboxes-" + courseId,
            data: 'deleteBoxFromCourse=' + boxId,
            success: function (data) {
                flashModal(data, 3000);
                // loadBoxesOfCourse();
                refreshPage();
            },
            error: function () {
                flashModal(i18n.gettext("Error while submitting the cardset data."));
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    } else {
        return false;
    }
}

function refreshCourseStatisticsPage() {
    var boxId = $("#staple").val();
    var period = $("#period").val();
    var courseId = $("#courseid").val();
    loadPageToCenter("course-statistics-" + courseId + "?boxId=" + boxId + "&period=" + period);
}

function createOneTimeCodes() {
    var numOfNewAccounts = $("#numOfNewAccounts").val();
    var courseId = $("#courseid").val();
    var numOfProMonths = 0;
    if ($("#numOfProMonths").length > 0) {
        numOfProMonths = $("#numOfProMonths").val();
    }
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-createusers-" + courseId,
        data: "numOfNewAccounts=" + numOfNewAccounts + "&numOfProMonths=" + numOfProMonths,
        success: function (data) {
            flashModal(data);
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the form data."));
        }, complete: function () {
            loadPageToCenter($('#pagehash').val());
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function saveCoursePassword() {
    var coursePassword = $("#coursePassword").val();
    var courseId = $("#courseid").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-createusers-" + courseId,
        data: "coursePassword=" + coursePassword,
        success: function (data) {
            flashModal(data);
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the form data."));
        }, complete: function () {
            loadPageToCenter($('#pagehash').val());
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function joinCourseByOneTimeData(callback) {
    var oneTimeId = $("#courseOneTimeId").val();
    var oneTimePassword = $("#courseOneTimePassword").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-join",
        data: "oneTimeId=" + oneTimeId + "&oneTimePassword=" + oneTimePassword,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            if (obj.success && obj.loggedIn) {
                if (typeof callback == 'function') {
                    callback();
                } else {
                    window.history.pushState(null, null, 'course-' + obj.courseId);
                    loadPageToCenter('course-' + obj.courseId);
                }
            } else if (obj.success) {
                loadPageByAjax('register_or_login?' + "courseOneTimeId=" + oneTimeId + "&courseOneTimePassword=" + oneTimePassword);
            }
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the ont time access data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function joinCourseByAccessCode() {
    var courseId = $("#courseId").val();
    var coursePassword = $("#coursePassword").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-join",
        data: "courseId=" + courseId + "&coursePassword=" + coursePassword,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            if (obj.success) {
                window.history.pushState(null, null, 'course-' + courseId);
                loadPageToCenter('course-' + courseId);
            }
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the form data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function joinCourseByInvitation(elem, event) {
    var courseId = $(elem).data("courseid");
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-join",
        data: "courseId=" + courseId + "&joinByInvitation=1"
    }).done(function (data) {
        var obj = JSON.parse(data.trim());
        flashModal(obj.message);
        if (obj.success) {
            window.history.pushState(null, null, 'course-' + courseId);
            loadPageToCenter('course-' + courseId);
        }
    }).fail(function () {
        flashModal(i18n.gettext("Error while submitting the form data."));
    }).complete(function () {
        $('#center').fadeTo(100, 1, 'swing');
    });
}

function courseMessageModal() {
    $("#course-message-modal").modal();
    setTimeout(function () {
        $("#course-message-modal").find("textarea").focus();
    }, 0);
    // loadNewsToFeedbackModal();
}

function courseMessageModalAction() {
    var courseId = $("#courseid").val();
    closeModal();
    flashModal(i18n.gettext('Sending message...'));
    var useInternalMessagingInstead = $("#useInternalMessagingInstead").is(":checked");
    $.ajax({
        type: "POST",
        url: "course-users-" + courseId,
        data: "useInternalMessagingInstead=" + useInternalMessagingInstead + "&message=" + encodeURIComponent($("#course-message-modal").find("textarea").val()),
        success: function (data) {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            if (obj.success) {
                $("#course-message-modal").find("textarea").val('').css('height', 'auto');
            }
        }
    });
}

function editCourseName() {
    $(".page-headline.input").attr("contenteditable", true);
    setTimeout(function () {
        $(".page-headline.input").focus();
    }, 0);
}

function courseNameFocus() {
    $(document).on("keypress.editheadline", function (e) {
        if (e.which == 13) {
            e.preventDefault();
            setTimeout(function () {
                $(".page-headline.input").blur();
            }, 0);
            $(document).off("keypress.editheadline");
        }
    });
    $(".edit-cardset-title-btn").hide();
}

function courseNameBlur() {
    $(document).off("keypress.editheadline");
    $(".edit-cardset-title-btn").show();
    $(".page-headline.input").attr("contenteditable", false);
    var courseId = $("#courseid").val();
    var value = encodeURIComponent($(".page-headline.input").html());
    $.ajax({
        type: "POST",
        url: "course-" + courseId,
        data: "setCourseName=" + value,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function courseAddProMonths(elem) {
    var userId = $(elem).data('userid');
    var proMonths = $(elem).val();
    var courseId = $("#courseid").val();
    var nonce = getNonce();
    if (confirm(i18n.gettext('Add PRO months (chargeable)?'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        flashModal(i18n.gettext("Please wait..."), 10000);
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "addProMonths=" + proMonths + "&userId=" + userId + "&nonce=" + nonce,
            success: function (data) {
                flashModal(data);
                loadUsersOfCourse();
            }, complete: function () {
                $('#center').fadeTo(300, 1, 'swing');
            }, error: function (xhr, status, text) {
                alert(i18n.gettext("Possibly failed to add the PRO-months. Please check!"));
                refreshPage();
            }
        });
    }
}

function courseUserPasswordModal(elem) {
    $("#course-user-password-modal").modal();
    $("#course-user-password-modal .action-btn").data("userid", $(elem).data("id"));
    var userId = $(elem).data('id');
    debug("Checking: " + "#userMailValidated-" + userId);
    var userMailValidated = $("#userMailValidated-" + userId).val();
    if (!(userMailValidated == 1)) {
        $("#course-user-password-modal #userMailPresentMsg").html(i18n.gettext("Please tell the attendee the password orally or in written form."));
    } else {
        $("#course-user-password-modal #userMailPresentMsg").html(i18n.gettext("The user will receive the new password by e-mail."));
    }
}

function courseUserPasswordModalAction(elem) {
    var password = $("#course-user-password-modal #newUserPass").val();
    var passwordRepetition = $("#course-user-password-modal #newUserPassControl").val();
    var userId = $(elem).data('userid');
    var courseId = $("#courseid").val();
    if (password !== passwordRepetition) {
        flashModal(i18n.gettext("Password and repetition do not match."));
    } else {
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "setNewPassword=" + password + "&userId=" + userId,
            success: function (data) {
                flashModal(data);
            }, complete: function () {
                $('#center').fadeTo(300, 1, 'swing');
            }
        });
    }
}

function courseUserAction(elem) {
    debug("User action is: " + elem.value);
    if (elem.value == 'delete') {
        deleteUserFromCourse(elem);
    } else if (elem.value == 'sendUserData') {
        sendCourseUserData(elem);
    } else if (elem.value == 'newPassword') {
        courseUserPasswordModal(elem);
    } else if (elem.value == 'makeLeader') {
        makeCourseLeader(elem);
    } else if (elem.value == 'degradeLeader') {
        degradeCourseLeader(elem);
    }
}

function loadUsersOfCourse() {
    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    $.ajax({
        type: "POST",
        url: url,
        data: "courseId=" + courseId + "&module=course-users",
        success: function (data) {
            if ($("#courseUsers").length > 0) {
                $("#courseUsers").html(data);
            }
        }
    });
}

function loadInvitationsOfCourse() {
    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    $.ajax({
        type: "POST",
        url: url,
        data: "courseId=" + courseId + "&module=course-invitations",
        success: function (data) {
            if ($("#courseInvitations").length > 0) {
                $("#courseInvitations").html(data);
            }
        }
    });
}

function loadBoxesOfCourse() {
    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    $.ajax({
        type: "POST",
        url: url,
        data: "courseId=" + courseId + "&module=course-cardboxes-admin",
        success: function (data) {
            if ($("#courseCardboxes").length > 0) {
                $("#courseCardboxes").html(data);
            }
        }
    });
}

function reAssignAllBoxes() {
    var courseId = $("#courseid").val();
    var url = $("#pagehash").val();
    flashModal(i18n.gettext("Started renewed assignment..."), 20000);
    $.ajax({
        type: "POST",
        url: url,
        data: "courseId=" + courseId + "&reAssign=1",
        success: function (data) {
            flashModal(data);
        }
    });
}

function changeCourseUserMailAddress(elem) {
    var courseId = $("#courseid").val();
    var defaultMailAddress = $(elem).data("mail");
    var userId = $(elem).data("userid");
    var mailAddress = prompt(i18n.gettext("Please enter a new mail address:"), defaultMailAddress);
    if (mailAddress !== null) {
        flashModal(i18n.gettext("Changing..."));
        $.ajax({
            type: "POST",
            url: "course-users-" + courseId,
            data: "changeMailAddress=" + mailAddress + "&userId=" + userId,
            success: function (data) {
                flashModal(data);
                loadUsersOfCourse();
            }
        });
    }
}

function retryMail(elem) {
    var mailId = $(elem).data("mailid");
    var recipient = $(elem).data("recipient");
    var courseId = $("#courseid").val();
    var alternateAddress = prompt(i18n.gettext("Correct recipient if necessary:"), recipient);
    $.ajax({
        type: "POST",
        url: "course-users-" + courseId,
        data: "retryMail=" + mailId + "&alternateAddress=" + alternateAddress,
        success: function (data) {
            flashModal(data);
        }
    });
}

function loadLearningTargetDetails(elem) {
    var userId = $(elem).data("userid");
    var courseId = $("#courseid").val();
    $('#learningTargetDetails_' + userId).toggle();
    if ($('#learningTargetDetails_' + userId).is(":visible")) {
        $.ajax({
            type: "POST",
            url: "course-target-" + courseId,
            data: "targetDetailsFor=" + userId + "&module=courseTargetDetails",
            success: function (data) {
                $("#learningTargetDetails_" + userId).html(data);
            }
        });
    }
}

function sendAllUserData() {
    var courseId = $("#courseid").val();
    $.get("course-users-" + courseId + "?" + "sendAllUserData=1", function (data) {
        flashModal(data);
    });
}

function applyToCourse() {
    var courseId = $("#courseid").val();
    flashModal(i18n.gettext("Applying..."));
    $.ajax({
        type: "POST",
        url: "course-" + courseId,
        data: "apply=1"
    }).done(function (data) {
        flashModal(data);
    }).fail(function (data) {

    });
}

function grantCourseApplication(el) {
    var courseId = $(el).data("courseid");
    var userId = $(el).data("userid");
    flashModal(i18n.gettext("Granting..."));
    $.ajax({
        type: "POST",
        url: "course-" + courseId,
        data: "grant=" + userId
    }).done(function (data) {
        flashModal(data);
    }).fail(function (data) {

    });
}

function inviteUserToCourseByName() {
    var courseId = $("#courseid").val();
    var userName = $("#findUsersForCourse").val();
    flashModal(i18n.gettext("Inviting..."));
    $.ajax({
        type: "POST",
        url: "course-createusers-" + courseId,
        data: "inviteByName=" + userName
    }).done(function (data) {
        var obj = JSON.parse(data);
        flashModal(obj.message);
    }).fail(function (data) {

    });
}

function joinBoxFromCourse(elem) {
    var boxId = $(elem).data('id');
    var courseId = $("#courseid").val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "course-cardboxes-" + courseId,
        data: 'joinBoxFromCourse=' + boxId,
        success: function (data) {
            flashModal(data, 3000);
            // loadBoxesOfCourse();
            refreshPage();
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the cardset data."));
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}