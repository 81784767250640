function executeTour(name, force) {
    if (i18nLoaded) {
        // debug("i18n loaded!");
        startTour(name, force);
    } else {
        // debug("i18n not yet loaded ...");
        setTimeout(function () {
            executeTour(name, force);
        }, 50);
    }
}

function startTour(name, force) {

    // debug("Start tour " + name + "?");

    if (force || $("#notour").length == 0) {

        if (typeof force === 'undefined') {
            force = false;
        }

        var userjoined = $("html").data("userjoined");
        var appversion = $("html").data("appversion");

        if (force) {
            localStorage.removeItem("tour_" + name + "_current_step");
            localStorage.removeItem("tour_" + name + "_end");
        }

        if (userjoined > appversion || force) {

            var steps = [];

            if (name == "main") {

                if (!force && $(".kartensatz-block:first").length > 0) {
                    steps.push({
                        // element: ".kartensatz-block:first",
                        title: i18n.gettext("Here we go!"),
                        content: i18n.gettext("Hi, we would like to shortly guide you through the basic control elements!")
                    });
                }

                if ($(".kartensatz-block:first").length > 0) {
                    steps.push({
                        element: ".kartensatz-block:first",
                        title: i18n.gettext("Cardset"),
                        content: i18n.gettext("This is a cardset. It contains separate cards containing questions and answers.")
                    });
                }
                if ($(".kartensatz-block:first").length > 0) {
                    steps.push({
                        element: ".kartensatz-block:first .learn-btn",
                        title: i18n.gettext("Cardset study button"),
                        content: i18n.gettext("Here you can start studying this cardset.")
                    });
                }
                if ($(".kartensatz-block:first .donut-chart:first").length > 0) {
                    steps.push({
                        element: ".kartensatz-block:first .donut-chart:first",
                        title: i18n.gettext("Cardset progress circle"),
                        content: i18n.gettext("This is the visualization of your learning progress. Your goal is to make it entirely green!")
                    });
                }

                if ($("#switchActiveInactiveCardsets").length > 0) {
                    steps.push({
                        element: "#switchActiveInactiveCardsets",
                        title: i18n.gettext("Active and inactive cardsets"),
                        content: i18n.gettext("Here you can switch between active and inactive cardsets. Active cardsets are part of your study schedule.")
                    });
                }

                if ($("#lernen").length > 0) {
                    steps.push({
                        element: "#lernen",
                        title: i18n.gettext("General study button"),
                        content: i18n.gettext("Here you can start a new study session which contains all active cardsets.")
                    });
                }
                if ($("#exam").length > 0) {
                    steps.push({
                        element: "#exam",
                        title: i18n.gettext("Exam button"),
                        content: i18n.gettext("Click here to take an exam. In an exam, you don't see the correct answer of a question immediately, and you will get a score which determines whether you would have passed a real exam or not.")
                    });
                }
                if ($("#lernplan-container").length > 0) {
                    steps.push({
                        element: "#lernplan-container",
                        title: i18n.gettext("Study schedule"),
                        content: i18n.gettext("This study schedule displays the amount of cards which you studied in the last few days, and the amount of cards which you should study in the next few days.")
                    });
                }
                if ($("#inactiveBoxesLink").length > 0) {
                    steps.push({
                        element: "#inactiveBoxesLink",
                        title: i18n.gettext("Inactive cardsets"),
                        content: i18n.gettext("Cardsets can be active or inactive! Only <b>active</b> cardsets are part of the general study schedule.")
                    });
                }
                
                if ($("#select-uni").length > 0) {
                    steps.push({
                        element: "#select-uni",
                        title: "Uni wechseln",
                        content: "Du kannst jederzeit Karten anderer Unis lernen. Wechsle dazu einfach den Unistandort und schaue, welche Fragen für Dein Studium wichtig sind."
                    });
                }
                
                if ($("#newCardsetButton").length > 0) {
                    steps.push({
                        element: "#newCardsetButton",
                        title: i18n.gettext("Creating new cardsets"),
                        content: i18n.gettext("And finally: If you want to create another cardset, click here.")
                    });
                }
                $(".tour-tour_main .btn-default").last().on("click", function () {
                    if ($(this).data("role") == "end") {
                        closeBanner(5);
                    }
                });
                
                closeBanner(5);
            }

            if (name == "cardset") {

                if (!force) {
                    steps.push({
                        title: i18n.gettext("This is your first cardset!"),
                        content: i18n.gettext("We would like to show you the basic elements of a cardset!")
                    });
                }

                if ($("#cardset-tab-info").length > 0) {
                    steps.push({
                        element: "#cardset-tab-info",
                        title: i18n.gettext("Cardset info"),
                        content: i18n.gettext("Here you see basic information about the cardset. If it's your own created cardset, you can change access rights here as well.")
                    });
                }
                if ($("#cardset-tab-cards").length > 0) {
                    steps.push({
                        element: "#cardset-tab-cards",
                        title: i18n.gettext("Cards"),
                        content: i18n.gettext("Here you see the list of cards. Try changing the sorting, filter, or current category!")
                    });
                }
                if ($("#cardset-tab-statistics").length > 0) {
                    steps.push({
                        element: "#cardset-tab-statistics",
                        title: i18n.gettext("Statistics"),
                        content: i18n.gettext("Here you see an overview about your learning progress for this cardset.")
                    });
                }
                if ($("#cardset-tab-people").length > 0) {
                    steps.push({
                        element: "#cardset-tab-people",
                        title: i18n.gettext("Colearners"),
                        content: i18n.gettext("If more than one person uses this specific cardset, you see a list of these people here.")
                    });
                }
                if ($("#cardset-tab-discussions").length > 0) {
                    steps.push({
                        element: "#cardset-tab-discussions",
                        title: i18n.gettext("Discussions"),
                        content: i18n.gettext("If you have colearners, you can discuss with them about the cardset and specific cards.")
                    });
                }
                if ($("#cardset-tab-settings").length > 0) {
                    steps.push({
                        element: "#cardset-tab-settings",
                        title: i18n.gettext("Cardset settings"),
                        content: i18n.gettext("Here you can reset your statistics, export or import cards (if it is your own created cardset), delete the cardset and do various other stuff.")
                    });
                }

                $(".tour-tour_cardset .btn-default").last().on("click", function () {
                    if ($(this).data("role") == "end") {
                        closeBanner(7);
                    }
                });

                closeBanner(7);
            }

            if (name == "new-cardset") {
                if ($("#cardset-name").length > 0) {
                    steps.push({
                        element: "#cardset-name",
                        title: i18n.gettext("Name of your new cardset"),
                        content: i18n.gettext("Here you enter a name for your new cardset. Choose it so it helps you to distinguish its content from other cardsets!")
                    });
                }
                if ($("#cardset-keywords_tagsinput").length > 0 && $("#cardset-keywords_tagsinput").is(":visible")) {
                    steps.push({
                        element: "#cardset-keywords_tagsinput",
                        title: i18n.gettext("Keywords for your new cardset"),
                        content: i18n.gettext("Here you can enter keywords which describe and categorize the content of your cardset. If you configure your cardset to be public, these keywords help other users to find your cardset!")
                    });
                }
                if ($(".coop-options-compact").length > 0 && $(".coop-options-compact").is(":visible")) {
                    steps.push({
                        element: ".coop-options-compact",
                        title: i18n.gettext("Access rights for your cardset"),
                        content: i18n.gettext("You can configure your cardset to be private and usable only by yourself, or also by your friends, or - if you like to share - also to all other users. Please check if this is the configuration of your choice for your cardset!")
                    });
                }
                if ($("#js-show-new-cardset-advanced-settings").length > 0) {
                    steps.push({
                        element: "#js-show-new-cardset-advanced-settings",
                        title: i18n.gettext("Advanced settings"),
                        content: i18n.gettext("Click here to see more options and actions like Text-to-Speech, categories inside of your cardset, and inviting friends!")
                    });
                }
                if ($(".page-headline-folder-container").length > 0) {
                    steps.push({
                        element: ".page-headline-folder-container",
                        title: i18n.gettext("Folder"),
                        content: i18n.gettext("Here you can select a folder in which the cardset should be contained.")
                    });
                }
                if ($("#createCardsetButton").length > 0) {
                    steps.push({
                        element: "#createCardsetButton",
                        title: i18n.gettext("Creating the cardset"),
                        content: i18n.gettext("And finally, when you are finished configuring the options to your needs, click here to create it!")
                    });
                }
                $(".tour-tour_new-cardset .btn-default").last().on("click", function () {
                    if ($(this).data("role") == "end") {
                        closeBanner(6);
                    }
                });
                
                closeBanner(6);
            }

            if (name == "study-config") {
//            if ($("").length > 0) {
//                steps.push({
//                    element: "",
//                    title: i18n.gettext(""),
//                    content: i18n.gettext("")
//                });
//            }
            }
            
            if (name == "course") {
                if ($("#courseAccessLinkBlock").length > 0 && $("#courseAccessLinkBlock").is(":visible")) {
                    steps.push({
                        element: "#courseAccessLinkBlock",
                        title: i18n.gettext("Adding members"),
                        content: i18n.gettext("Share this link to add members easily!")
                    });
                }
            }
            
//            if (name == "study") {
//                if ($("#half-known-btn").length > 0) {
//                    steps.push({
//                        element: "#half-known-btn",
//                        title: i18n.gettext("Partially Known"),
//                        content: i18n.gettext("Press this button if you did not know the answer completely.")
//                                + " " + i18n.gettext(" As a consequence, the study level of the card is not always dropped to 0, but only reduced by 1.")
//                                + " " + i18n.gettext("You can hide this button in the settings.")
//                    });
//                }
//                $(".tour-tour_cardset .btn-default").last().on("click", function () {
//                    if ($(this).data("role") == "end") {
//                        closeBanner(9);
//                    }
//                });
//                
//                closeBanner(9);
//            }

            // debug("Force is: " + force);
            if (($("#restart-tour-" + name).length > 0) && !force) {
                steps.push({
                    element: "#restart-tour-" + name,
                    title: i18n.gettext("Restart the tour"),
                    content: i18n.gettext("If you want to watch this tour again, click here!")
                });
            }

            if (steps.length > 0) {
                var tour = new Tour({
                    backdrop: true,
                    name: "tour_" + name,
                    orphan: true,
                    template: '<div class="popover" role="tooltip"> <div class="arrow"></div> <h3 class="popover-title"></h3> <div class="popover-content"></div> <div class="popover-navigation"> <div class="btn-group"> <button class="btn btn-sm btn-default" data-role="prev">&laquo; ' + i18n.gettext('Prev') + '</button> <button class="btn btn-sm btn-default" data-role="next">' + i18n.gettext('Next') + ' &raquo;</button> <button class="btn btn-sm btn-default" data-role="pause-resume" data-pause-text="' + i18n.gettext('Pause') + '" data-resume-text="' + i18n.gettext('Resume') + '">' + i18n.gettext('Pause') + '</button> </div> <button class="btn btn-sm btn-default" data-role="end">' + i18n.gettext('End tour') + '</button> </div> </div>',
                    steps: steps});

                // Initialize the tour
                tour.init();

                // debug("Starting tour " + name + " ...");
                tour.start();
            }

        } else {
            // debug("Do not start tour " + name + ". User joined: " + userjoined + " is not larger than " + appversion + ". Force is " + force + ".");
        }

    }

}