function submitToReviewModal() {

    var boxId = $("#boxId").val();
    $.ajax({
        type: "POST",
        url: "cardset-settings-" + boxId,
        data: "checkIfCardsetIsReadyForSubmit=1"
    }).done(function (data) {
        var obj = JSON.parse(data.trim());
        if (obj.success) {
            $("#submit-to-review-modal").modal();
        } else {
            generalPurposeModal(obj.message, "Hinweis");
            // $(".js-kind-of-list").val("all");
            // $(".js-show-category").val(-1);
            // searchAndReorderCardBox();
        }
    });

}

function submitToReview() {

    var nonce = getNonce();
    var boxId = $("#boxId").val();

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "cardset-settings-" + boxId,
        data: "submitToReview=1" + "&nonce=" + nonce
    }).done(function (data) {
        generalPurposeModal(`Vielen Dank!
Du hast Deinen Kartensatz eingereicht. Nach erfolgreicher Prüfung erhältst Du Deine Mitgliedschaftsverlängerung als Bonus.

Deine Vorteile: 
- Von Deinen selbst erstellten Fragen kannst Du perfekt auf Deine eigenen Prüfungen lernen. 
- Auch die Kommilitonen Deiner Uni profitieren.
`, i18n.gettext("Success!"));
        $("#share-" + boxId).hide();
    }).fail(function (data) {
        flashModal(i18n.gettext("Error while submitting the cardset for review."));
    }).complete(function () {

    });

}

function startReview() {

    var nonce = getNonce();
    var boxId = $("#boxId").val();

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "cardset-settings-" + boxId,
        data: "startReview=1" + "&nonce=" + nonce
    }).done(function (data) {
        generalPurposeModal("Kartensatz zum Review akzeptiert.", i18n.gettext("Success!"));
        $("#review-" + boxId).hide();
        $("#reject-" + boxId).hide();
        loadPageByAjax("review-cards?boxId=" + data);
    }).fail(function (data) {
        flashModal(i18n.gettext("Error while accepting the cardset for review."));
    }).complete(function () {

    });

}

function rejectReview() {

    var nonce = getNonce();
    var boxId = $("#boxId").val();

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "cardset-settings-" + boxId,
        data: "rejectReview=1" + "&nonce=" + nonce
    }).done(function (data) {
        generalPurposeModal("Kartensatz zum Review abgelehnt.", i18n.gettext("Success!"));
        $("#review-" + boxId).hide();
        $("#reject-" + boxId).hide();
    }).fail(function (data) {
        flashModal(i18n.gettext("Error while rejecting the cardset for review."));
    }).complete(function () {

    });

}

function publishReviewedCards() {

    var nonce = getNonce();
    var boxId = $("#boxId").val();
    var checkedValues = $('.js-releaseCard:checked').map(function () {
        return this.value;
    }).get();

    flashModal(i18n.gettext("Please wait..."));

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "review-cards?boxId=" + boxId,
        data: "publishCards=" + JSON.stringify(checkedValues) + "&nonce=" + nonce
    }).done(function (data) {
        generalPurposeModal(data, i18n.gettext("Success!"));
        loadPageByAjax('review-cardboxes');
    }).fail(function (data) {
        flashModal("Fehler beim Veröffentlichen der Karten.");
    }).complete(function () {

    });

}

function markReviewOfCardsetAsFinished() {

    var boxId = $("#boxId").val();

    var doit = confirm("Wirklich Review abschließen? Die noch nicht veröffentlichten Karten werden verworfen.");

    if (doit) {
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "review-cards?boxId=" + boxId,
            data: "markAsFinished=1"
        }).done(function (data) {
            generalPurposeModal(data, i18n.gettext("Success!"));
            loadPageByAjax('review-cardboxes');
        }).fail(function (data) {
            flashModal("Fehler.");
        }).complete(function () {

        });
    }

}