function switchCardsListLayout() {
    var boxId = $(".page-top").data("box-id");
    var currentLayout = getCookie('cardsetCardsLayout-' + boxId);
    if (currentLayout == "expanded") {
        $("#cardset-cards-content").removeClass("expanded");
        $("#cardset-cards-content").addClass("tiles");
        setCookie('cardsetCardsLayout-' + boxId, "tiles", 9999);
        shortInfo(i18n.gettext("Switched to tiles"));
    } else if (currentLayout == "tiles") {
        $("#cardset-cards-content").removeClass("tiles");
        $("#cardset-cards-content").addClass("compact");
        setCookie('cardsetCardsLayout-' + boxId, "compact", 9999);
        shortInfo(i18n.gettext("Switched to compact view"));
    } else {
        $("#cardset-cards-content").removeClass("tiles");
        $("#cardset-cards-content").removeClass("compact");
        $("#cardset-cards-content").addClass("expanded");
        setCookie('cardsetCardsLayout-' + boxId, "expanded", 9999);
        shortInfo(i18n.gettext("Switched to display of full answer"));
    }
}

function applyForLearning(el) {
    var id = $(el).data("id");
    shortInfo(i18n.gettext("Applying..."));
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + id,
        data: "applyForLearning=1",
        success: function (data) {
            shortInfo(i18n.gettext('You applied for co-studying.'));
            $(el).parent().addClass("applied");
        }
    });
}

function retractApplicationForLearning(el) {
    var id = $(el).data("id");
    shortInfo(i18n.gettext("Retracting..."));
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + id,
        data: "unApplyForLearning=1",
        success: function (data) {
            shortInfo(i18n.gettext('You canceled your application for co-studying.'));
            $(el).parent().removeClass("applied");
        }
    });
}

function searchAndReorderCardBox(el) {
    
    if ($(".multiple-new-cards-container").is(":visible")) {
        cancelCreateCard($(".cancel-create-card-btn"), true);
    }
    
    var parentNode = $(el).closest(".js-menu-light-bg").length ? ".js-menu-light-bg" : ".top-part";
    var orderby = $(parentNode + " .js-order-by").val();
    var kindOfList = $(parentNode + " .js-kind-of-list").val();
    var showCategory = $(parentNode + " .js-show-category").val();
    var text = encodeURIComponent($('.js-cardbox-search-input').val());
    var boxId = $("#boxId").val();
    $('#cardset-content').fadeTo(400, 0.3);
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "orderby=" + orderby + "&kindOfList=" + kindOfList + "&showCategory=" + showCategory + "&text=" + text + "&module=kartensatz_karten",
        success: function (data) {
//            try {
            $('#cardset-content').html(data);
            loadCardsContent();
//            } catch (e) {
//                debug('Html parse error');
//            }
            $('#cardset-content').fadeTo(400, 1.0);
        }
    });
}

function cardboxSearchboxKeypress(e) {
    if (e.keyCode == 13) {
        searchAndReorderCardBox();
    }
}

function getPreviousOrNextCardHref(el) {
    var mystr = $(el).data('id');
    if (mystr != null) {
        var myarr = mystr.split("-");
        var cardId = myarr[1];
        $(el).attr("href", 'card-' + cardId);
    }
}



// used in several modals
function categoryInputBlur(el) {
    var boxId = $('#boxId').val();
    var mystr = $(el).attr('id');
    var categoryName = encodeURIComponent(el.value);
    // button move-cards-to-category-btn-card verstecken
    $('#move-cards-to-category-btn-card').addClass('savingCategory');
    $('#multiple-cards-select-categories-modal .action-btn').addClass('savingCategory');
    debug(4);
    var isNumeric = false;
    var categoryId = null;
    if (mystr != null) {
        var myarr = mystr.split("-");
        categoryId = myarr[1];
        isNumeric = !isNaN(parseFloat(categoryId)) && isFinite(categoryId);
    }

    if (isNumeric) {
        var myarr = mystr.split("-");
        var categoryId = myarr[1];
        debug("Existierende Kategorie aktualisieren: " + categoryId);
        $('#' + mystr).fadeTo(100, 0.5);
        $.ajax({
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "renameCategory=" + categoryId + "&categoryName=" + categoryName,
            success: function (data) {
                $('#' + mystr).fadeTo(50, 1.0);
                $('#move-cards-to-category-btn-card').removeClass('savingCategory');
                $('#multiple-cards-select-categories-modal .action-btn').removeClass('savingCategory');
            }
        });
    } else {
        debug("Neue Kategorie hinzufuegen");
        // $('#' + mystr).fadeTo(100, 0.5);
        var nonce = getNonce();
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "createCategory=" + categoryName + "&nonce=" + nonce,
            success: function (data) {
//                try {
                var catObj = JSON.parse(data.trim());
                // unbedingt id für Textfeld und Loeschen-Button im HTML-Code setzen!
                var categoryId = catObj.categoryId;
                $(el).attr('id', 'editCategory-' + categoryId);
                $(el).next('button').attr('id', 'deleteCategory-' + categoryId);
                $(el).prev('span').find('input').attr('id', 'category-' + categoryId + '-checkbox');
                $('#' + mystr).fadeTo(50, 1.0);
                $('#move-cards-to-category-btn-card').removeClass('savingCategory');
                $('#multiple-cards-select-categories-modal .action-btn').removeClass('savingCategory');
                refreshCategoriesPulldownList(boxId);
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }

            }
        });
    }
}



function modalRemoveCategory(el) {
    var boxId = $('#boxId').val();
    var mystr = $(el).attr('id');
    if (mystr != null) {
        var r = confirm(i18n.gettext("Delete really?"));
        if (r == true) {
            var myarr = mystr.split("-");
            var categoryId = myarr[1];
            debug("Lösche Kategorie " + categoryId);
            $.ajax({
                type: "POST",
                url: "kartensatz-karten-" + boxId,
                data: "deleteCategory=" + categoryId,
                success: function (data) {
                    var answer = JSON.parse(data.trim());
                    if (answer.success) {
                        $(".manageCategory-" + categoryId).remove();
                    } else {
                        alert(answer.message);
                    }
                }
            });
        }
    }
}



// !!! needs to be checked if this is still neccessary !!!
$(document).on('click', ".embedded-audio", function () {
    event.stopPropagation();
});



// WHAT IS THIS FOR???
// $(document).on('click', ".import-box-btn", function () {

// });

// $(document).on('click', ".embed-box-btn", function () {

// });

function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function addSortableFeature() {
    var boxId = $("#cardset-cards-content").data("box-id");
    var scrollFn;
    var canceledSort = false;

    if ($("html").data("prouser") == "1") {
        $(".sortable").sortable({
            forcePlaceholderSize: true,
            scroll: false,
            // fix helper element offset in FF and IE
            start: function (event, ui) {
                var sortAllowed = $("#sortAllowed").val();
                if ($("html").data("prouser") != "1") {
                    dragDropProHint();
                    canceledSort = true;
                    $(".sortable").sortable("cancel");
                    $(document).trigger("mouseup");
                } else if (sortAllowed == 0) {
                    dragDropNotAllowedHint();
                } else {
                    if ($("html").is(".iexplorer, .firefox")) {
                        ui.item.css('margin-top', $(window).scrollTop());
                    }

                    // scroll when dragging to top or bottom
                    var originalMarginTop = ui.item.css('margin-top');
                    $("body").append('<div class="js-scroll-area top" style="width: 100%; height: 100px; position: fixed; top: 0px; left: 0px; z-index:9999999; cursor: pointer"></div>');
                    $("body").append('<div class="js-scroll-area bottom" style="width: 100%; height: 60px; position: fixed; bottom: 0px; left: 0px; z-index:9999999; cursor: pointer"></div>');
                    $(".js-scroll-area").mouseenter(function () {
                        debug('mouseenter');
                        var scrollDelta = $(this).hasClass("top") ? -30 : 30;
                        scrollFn = setInterval(function () {
                            debug('interval');
                            $(window).scrollTop($(window).scrollTop() + scrollDelta);
                            // ui.item.css('margin-top', $(window).scrollTop());
                            if ($("html").is(".iexplorer, .firefox")) {
                                ui.item.css('margin-top', $(window).scrollTop());
                            }
                        }, 50);
                    });
                    $(".js-scroll-area").mouseleave(function () {
                        debug('mouseleave');
                        clearInterval(scrollFn);
                    });

                    canceledSort = false;
                    // add event handler for canceling sorting with ESC
                    $(document).on("keyup.cancelSortESC", ".page-kartensatz", function (e) {
                        if (e.keyCode == 27) {
                            canceledSort = true;
                            $(".sortable").sortable("cancel");
                        }
                    });
                }
            },
            stop: function (event, ui) {
                $(".js-scroll-area").remove();
                // remove event handler for canceling sorting with ESC
                $(document).off("keypress.cancelSortESC");
            },
            beforeStop: function (event, ui) {
                if ($("html").is(".iexplorer, .firefox")) {
                    debug("stop fix!");
                    ui.item.css('margin-top', 0);
                }
            },
            update: function (e, ui) {
                if (!canceledSort) {
                    // WHAT TO DO WHEN CHANGING THE ORDER
                    var sortAllowed = $("#sortAllowed").val();
                    if ($("html").data("prouser") != "1") {
                        dragDropProHint();
                    } else if (sortAllowed == 0) {
                        dragDropNotAllowedHint();
                    } else {
                        var myarr = ui.item.attr("id").split("-");
                        var cardId = myarr[2];

                        // save the new order!
                        debug("New position of card " + cardId + " should be " + (ui.item.index() + 1));
                        $.ajax({
                            type: "POST",
                            url: "kartensatz-karten-" + boxId,
                            data: "cardId=" + cardId + "&moveToOrderIndex=" + (ui.item.index() + 1),
                            success: function (msg) {
                                shortInfo(msg, 2500);
                            },
                            error: function () {
                                shortInfo(i18n.gettext('Error while moving the card.'));
                            }
                        });
                    }
                }
            }
        });
    }
}

function loadAllCards() {

    var boxId = $("#cardset-cards-content").data("box-id");
    var categoryId = $("#cardset-cards-content").data("show-category");
    var kindOfList = $("#cardset-cards-content").data("kind-of-list");
    var orderby = $("#cardset-cards-content").data("orderby");
    var text = $("#cardset-cards-content").data("text");

    if (!isNaN(boxId) && boxId > 0) {
        $("#behind-cardset-cards-content").unbind('inview');
        $("#behind-cardset-cards-content").html('');
        $("#cardset-cards-content").html('');
        showAjaxLoader("#cardset-cards-content");
        $.ajax({
            type: "GET",
            url: "kartensatz-karten-inhalte",
            context: this,
            data: "boxId=" + boxId + "&showCategory=" + categoryId + "&kindOfList=" + kindOfList + "&orderby=" + orderby + "&text=" + text,
            success: function (data) {
                hideAjaxLoader();
                var cardsObj = JSON.parse(data.trim());
                var numOfCards = parseInt(cardsObj.numOfCards);

                var counter = 0;

                $('.js-cardset-card-quantity').html(numOfCards);
                $(".kartensatz-selected-cards-quantity").text($(".hover-layer.selected").length);

                $.each(cardsObj.cards, function (index, card) {
                    
                    if (typeof(card.categoryNames) == 'undefined') {
                        card.categoryNames = '';
                    }
                    
                    var selectedForLearning = false;
                    if (card.buyable === "1" || card.buyable === 1 || card.selectedForLearning === "1" || card.selectedForLearning === 1) {
                        selectedForLearning = true;
                    }
                    var cardString = createCardForList(card, selectedForLearning, orderby, kindOfList);
                    if ($("#cardset-cards-content").data("box-id") == boxId) {
                        $('#cardset-cards-content').append(cardString);
                        counter++;
                    }
                });

                if ($.cookie("cardsetCardsLayout-" + boxId) === "expanded") {
                    $(".question-text").removeClass('ellipsis');
                    $(".answer-text").removeClass('ellipsis');
                    $(".card.one-line .question").css('height', 'auto');
                    $(".card.one-line .answer").css('height', 'auto');
                }

                // Only if sorting allowed, so only for leader or buyer of cardbox:
                addSortableFeature();

                if ($(".card-wrapper").hasClass("edit-multiple")) {
                    $(".card-wrapper").addClass("edit-multiple");
                }

                var numOfVisibleCards = $("#cardset-cards-content .card-wrapper").length;

                $("#cardset-cards-content").data("offset", numOfVisibleCards);

                $("#behind-cardset-cards-content").unbind('inview');
                $("#behind-cardset-cards-content").html('');
            }
        });
    }
}

function loadCardsContent(refresh) {

    if (typeof refresh === 'undefined') {
        refresh = false;
    }

    if (refresh) {
        $("#cardset-cards-content").data("offset", 0);
        $("#behind-cardset-cards-content").off('inview');
    }

    var boxId = $("#cardset-cards-content").data("box-id");
    var categoryId = $("#cardset-cards-content").data("show-category");
    var kindOfList = $("#cardset-cards-content").data("kind-of-list");
    var orderby = $("#cardset-cards-content").data("orderby");
    var text = $("#cardset-cards-content").data("text");
    var limit = $("#cardset-cards-content").data("limit");
    var offset = $("#cardset-cards-content").data("offset");

    if ($(".card-wrapper").hasClass("prepare-edit-multiple")) {
        $('#cardset-cards-content').fadeTo(300, 0.5);
    }

    if (!isNaN(boxId) && boxId > 0) {
        if (offset == 0) {
            $('#cardset-cards-content').html('');
        }
        $.ajax({
            type: "GET",
            url: "kartensatz-karten-inhalte",
            context: this,
            data: "boxId=" + boxId + "&showCategory=" + categoryId + "&kindOfList=" + kindOfList + "&orderby=" + orderby + "&text=" + text + "&limit=" + limit + "&offset=" + offset,
            success: function (data) {
//                try {
                hideAjaxLoader();
                if ($(".card-wrapper").hasClass("prepare-edit-multiple")) {
                    $('#cardset-cards-content').fadeTo(300, 1);
                }

                var cardsObj = JSON.parse(data.trim());
                var numOfCards = parseInt(cardsObj.numOfCards);

                // cardSet.cardQuantity = numOfCards;

                var counter = 0;

                $('.js-cardset-card-quantity').html(numOfCards);
                if ($('.numOfExampleCardsBuyable').length > 0) {
                    $('.numOfExampleCardsBuyable').html(cardsObj.cards.length);
                }

                $.each(cardsObj.cards, function (index, card) {
                    
                    if (typeof(card.categoryNames) == 'undefined') {
                        card.categoryNames = '';
                    }
                    
                    // debug('card index',index);
                    // debug("card.selectedForLearning: " + card.selectedForLearning);
                    // debug("card.buyable: " + card.buyable);
                    var selectedForLearning = false;
                    if (card.buyable === "1" || card.buyable === 1 || card.selectedForLearning === "1" || card.selectedForLearning === 1) {
                        selectedForLearning = true;
                    }
                    // debug("Selected for learning: " + selectedForLearning);
                    var cardString = createCardForList(card, selectedForLearning, orderby, kindOfList);
                    if ($("#cardset-cards-content").data("box-id") == boxId) {
                        $('#cardset-cards-content').append(cardString);
                        counter++;
                    }
                });

                if ($.cookie("cardsetCardsLayout-" + boxId) === "expanded") {
                    $(".question-text").removeClass('ellipsis');
                    $(".answer-text").removeClass('ellipsis');
                    $(".card.one-line .question").css('height', 'auto');
                    $(".card.one-line .answer").css('height', 'auto');
                }

                addSortableFeature();

                if ($(".card-wrapper").hasClass("edit-multiple")) {
                    $(".card-wrapper").addClass("edit-multiple");
                }

                var numOfVisibleCards = $("#cardset-cards-content .card-wrapper").length;

                $("#cardset-cards-content").data("offset", numOfVisibleCards);

                if (numOfVisibleCards == cardsObj.numOfCards) {
                    $("#behind-cardset-cards-content").unbind('inview');
                    $("#behind-cardset-cards-content").html('');
                } else if (offset == 0) {
                    if (!($('.numOfExampleCardsBuyable').length > 0)) {
                        $("#behind-cardset-cards-content").on('inview', function (event, isInView, visiblePartX, visiblePartY) {
                            debug("*** Ende der Liste in Sicht: Lade weitere Karten! ***");
                            if (isInView) {
                                loadCardsContent();
                            }
                        });
                    }
                }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }

            }
        });
    }
}

function dragDropProHint() {
    repeticoPromptModal(sprintf(i18n.gettext('Re-sorting using drag and drop is only possible with a %s-PRO account!'), sprintf('<span style="font-weight:bold;">%s PRO</span>', $("html").data("apptitle"))));
    setProPurchaseTrigger('sort-by-drag-and-drop');
}

function dragDropNotAllowedHint() {
    $("#general-purpose-modal-content").html(sprintf(i18n.gettext('Sorting of flashcard not allowed in this cardset.'), 'Repetico'));
    $("#general-purpose-modal").modal();
}

function createCardForList(card, selectedForLearning, orderby, kindOfList) {

    if (card.questionText == '') {
        // questionText = '&#8230;';
        card.questionText = '[' + i18n.gettext('No text') + ']';
    }
    if (card.mchoice == 1) {
        card.questionAnswer = '';
        try {
            var mchoiceAnswers = JSON.parse(card.mchoice_content);
            jQuery.each(mchoiceAnswers, function (index, answer) {
                if (index > 0) {
                    card.questionAnswer += '<br>';
                }
                var choiceBoxType = "checkbox";
                if (card.forceSingleChoice == true) {
                    var choiceBoxType = "radio";
                }
                if (answer.correct == 1) {
                    card.questionAnswer += '<input class="mchoice-checkbox" disabled="disabled" checked="checked" type="' + choiceBoxType + '" style="vertical-align: middle; color: #777;"> ';
                } else {
                    card.questionAnswer += '<input class="mchoice-checkbox" disabled="disabled" type="' + choiceBoxType + '" style="vertical-align: middle; color: #777;"> ';
                }
                card.questionAnswer += answer.answer;
            });
        } catch (e) {
            debug("Problem while handling multiple choice answers: " + card.mchoice_content);
        }
        // questionAnswer = mchoiceContent;
    } else if (card.questionAnswer == '') {
        card.questionAnswer = '&#8230;';
    }
    
    // debug("Karte zum Lernen ausgewaehlt? " + selectedForLearning);

    var cardString = '<div class="card-wrapper" id="card-wrapper-' + card.questionKey + '">';

    cardString += '<div class="hover-layer click" onclick="editMultipleCardsCardToggle(this,event)"></div>';
    cardString += '<span class="custom-checkbox select-multiple">';
    cardString += '<input type="checkbox" id="card-' + card.questionKey + '-checkbox">';
    cardString += '<!-- <span class="box">';
    cardString += '<i class="fa fa-check"></i>';
    cardString += '</span> -->';
    cardString += '</span>';

    if (card.orderIndex != 0) {
        cardString += '<span class="cardnumber show-for-1024-up-inline">' + card.orderIndex + '</span>';
    }
    
    cardString += '<div class="card one-line';
    if ((selectedForLearning == '' || selectedForLearning == null || !selectedForLearning) && $("html").data("loggedin") == "1") {
        cardString += ' deactivated';
    }
    cardString += '"';
    if (typeof orderby != 'undefined' && orderby == "fixedOrder" && $("html").data("prouser") != "1") {
        cardString += ' ondragstart="dragDropProHint();"';
    } else if (typeof orderby != 'undefined' && orderby == "fixedOrder" && $("#sortAllowed").val() == "0") {
        cardString += ' ondragstart="dragDropNotAllowedHint();"';
    }
    cardString += '>';
    cardString += '<a class="bg-link-a w100 h100" id="card-' + card.questionKey + '" href="' + "card-" + card.questionKey + '"></a>';
    cardString += '<div class="question" id="question-' + card.questionKey + '">';
    cardString += '<div class="bin ';
    if (card.knownInPlannedRow == 0 && card.lastPlannedPlayed == '0000-00-00 00:00:00') {
        cardString += 'bin-new';
    } else if (card.knownInPlannedRow > 5) {
        cardString += 'bin-5';
    } else {
        cardString += 'bin-' + card.knownInPlannedRow;
    }
    cardString += '" title="';
    if (card.lastPlannedPlayed == '0000-00-00 00:00:00') {
        cardString += i18n.gettext("Not yet in the study schedule");
    } else {
        cardString += card.knownInPlannedRow + ' ' + i18n.gettext("times known in row in the study schedule");
    }
    cardString += '"></div>';
    
    if (orderby == "lasttimeplayed" || kindOfList == "known" || kindOfList == "partiallyKnown" || kindOfList == "notKnown") {
        cardString += '<div class="categoryNames">' + i18n.gettext("Last time queried") + ': ' + card.lastPlayed + '</div>';
    } else if (orderby == "updated") {
        cardString += '<div class="categoryNames">' + i18n.gettext("Updated") + ': ' + card.updated + '</div>';
    } else if (orderby == "new") {
        cardString += '<div class="categoryNames">' + i18n.gettext("Created") + ': ' + card.created + '</div>';
    } else if (orderby == "difficulty") {
        cardString += '<div class="categoryNames">'
                + ' <span style="color:#00A279;"><i class="fa fa-check"></i>' + card.timesKnown
                + ' </span><span style="color:#e79e3d;"><i class="fa fa-pie-chart"></i>' + card.timesHalfKnown
                + ' </span><span style="color:#DB4646;"><i class="fa fa-times"></i>' + card.timesNotKnown
                + ' </span></div>';
    } else if (orderby == "nexttimeplay") {
        cardString += '<div class="categoryNames">' + i18n.gettext("Next in schedule") + ': ' + card.realDaysToWait + " " + i18n.gettext("days") + '</div>';
    } else if (typeof(card.categoryNames) !== 'undefined' && card.categoryNames != "" && card.categoryNames != null) {
        cardString += '<div class="categoryNames">' + card.categoryNames.replaceAll('#REPSEP#',', ') + '</div>';
    }
    
    cardString += '<div class="question-text">' + card.questionText + '</div>';
    cardString += '</div>';
    cardString += '<div class="answer" id="answer-' + card.questionKey + '">';
    cardString += '<div class="answer-text">';
//    if (!(mchoice == 1)) {
    cardString += card.questionAnswer.replaceAll("#Audio#", "");
//    } else {
//        cardString += '[Multiple Choice]';
//    }
    cardString += '</div>';
    if ($("html").data("loggedin") == "1") {
        cardString += '<button class="favorite-btn js-favorite-btn right';
        if (card.favorite == 1) {
            cardString += ' favorite';
        }
        cardString += '" data-card-id="' + card.questionKey + '"';
        if (card.favorite == 1) {
            cardString += 'title="' + i18n.gettext('Remove from favorites') + '" onClick="favoriteCard(this)">';
        } else {
            cardString += 'title="' + i18n.gettext('Add to favorites') + '" onClick="favoriteCard(this)">';
        }
        cardString += '<i class="fa fa-star ';
        cardString += '"></i>';
        cardString += '</button>';
    }
    cardString += '</div></div> <!-- /.card --> </div>';


    /*
     var cardString = '<div class="hover-layer click"></div>';
     cardString += '<span class="custom-checkbox select-multiple">';
     cardString += '<input type="checkbox" id="card-' + questionKey + '-checkbox">';
     cardString += '<!-- <span class="box">';
     cardString += '<i class="fa fa-check"></i>';
     cardString += '</span> -->';
     cardString += '</span>';
     
     cardString += '<div style="background-color: #f2f2f2; border-width: 0;color: black;display: block;margin-bottom: 5px;margin-left: 3px;margin-top: 5px;padding: 5px;text-align: left; width: 49%; float: left; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" id="karte-' + questionKey + '">';
     cardString += questionText;
     cardString += '</div>';
     
     cardString += '<div style="background-color: #eee; border-width: 0;color: black;display: block;margin-bottom: 5px;margin-left: 3px;margin-top: 5px;padding: 5px;text-align: left; width: 49%; float: left; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" id="karte-' + questionKey + '">';
     cardString += questionAnswer;
     cardString += '</div>';
     */

    return cardString;

}


// EDIT MULTIPLE CARDS

function cancelEditMultipleCards() {
    $(".standard-btns-container").show();
    $(".edit-multiple-cards-btns-container").hide();
    $(".edit-multiple-toolbar").hide();
    $(".card-wrapper").removeClass("edit-multiple");
    $(".hover-layer").removeClass("selected");
    $(".card-wrapper").find("input").prop("checked", false);
    $(".js-cardset-card-quantity").show();
    $(".kartensatz-selected-cards-quantity").text(0).hide();

    /*
     var boxId = $("#boxId").val();
     $('#cardset-content').fadeTo(400, 0.3);
     
     $.ajax({
     type: "POST",
     url: "kartensatz-karten-" + boxId,
     data: "&module=kartensatz_karten",
     success: function(data){
     $('#cardset-content').html(data);
     $('#cardset-content').fadeTo(400, 1.0);
     }
     });
     */
}

function activateMultipleCards() {
    // what are the IDs of the selected cards?
    var boxId = $('#boxId').val();
    var items = getSelectedCardIdsAsArray();
    // activate by ajax
    flashModal(i18n.gettext('Activating...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "activateCards=" + items,
        success: function (data) {
            var countAlreadyActivated = $(".hover-layer").filter(".selected").siblings(".card").not(".deactivated").length;
            var countNowActivated = $(".hover-layer").filter(".selected").length - countAlreadyActivated;
            $(".hover-layer").filter(".selected").siblings(".card").removeClass("deactivated");
            if (countAlreadyActivated > 0) {
                var helperString = countAlreadyActivated + ' ' + i18n.gettext("cards were already active") + '.'
            } else {
                var helperString = "";
            }
            flashModal(countNowActivated + ' ' + i18n.gettext("cards activated") + '. ' + helperString);
        }
    });
}

function deactivateMultipleCards() {
    // what are the IDs of the selected cards?
    var boxId = $('#boxId').val();
    var items = getSelectedCardIdsAsArray();
    flashModal(i18n.gettext('Deactivating...'));
    // activate by ajax
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "deactivateCards=" + items,
        success: function (data) {
            var countAlreadyDeactivated = $(".hover-layer").filter(".selected").siblings(".card").filter(".deactivated").length;
            var countNowDeactivated = $(".hover-layer").filter(".selected").length - countAlreadyDeactivated;
            $(".hover-layer").filter(".selected").siblings(".card").addClass("deactivated");
            if (countAlreadyDeactivated > 0) {
                var helperString = countAlreadyDeactivated + ' ' + i18n.gettext("cards were already inactive") + '.'
            } else {
                var helperString = "";
            }
            flashModal(countNowDeactivated + ' ' + i18n.gettext('Cards deactivated.') + ' ' + helperString);
        }
    });
}

function editMultipleCardsSelectAllToggle(el) {
    if ($(el).hasClass("deselect")) {
        $(el).find(".fa-check").hide();
        $(el).find(".fa-minus").hide();
        $(".card-wrapper").find(".hover-layer").removeClass("selected");
        $(".card-wrapper").find("input").prop("checked", false);
        $(el).removeClass("deselect");
        $(el).find("label").html(i18n.gettext("Select all"));
    } else {
        $(el).find(".fa-check").show();
        $(".card-wrapper").find(".hover-layer").addClass("selected");
        $(".card-wrapper").find("input").prop("checked", true);
        $(el).addClass("deselect");
        $(el).find("label").html(i18n.gettext("Unselect all"));
    }
    $(".kartensatz-selected-cards-quantity").text($(".hover-layer.selected").length);
}


var lastClickedCard; // save last clicked card for shift+click operation

function editMultipleCardsMode() {
//		var numOfVisibleCards = $("#cardset-cards-content .card-wrapper").length;
//		var numOfAllCards = parseInt($(".js-cardset-card-quantity").text());
//		var numOfRemainingCards = numOfAllCards - numOfVisibleCards;
//
//		$(".card-wrapper").addClass("prepare-edit-multiple");
//
//        if (numOfRemainingCards > 0) {
//            shortInfo(i18n.gettext('Vervollst&auml;ndige Liste der Karten...'));
//			$("#cardset-cards-content").data("offset", numOfVisibleCards);
//			$("#cardset-cards-content").data("limit", numOfRemainingCards);
//			loadCardsContent();
//            $("#behind-cardset-cards-content").unbind('inview');
//        } else {
    $(".standard-btns-container").hide();
    $(".edit-multiple-cards-btns-container").show();
    $(".card-wrapper").addClass("edit-multiple");
    $(".edit-multiple-toolbar").show();
    $(".js-cardset-card-quantity").hide();
    $(".kartensatz-selected-cards-quantity").show();
    lastClickedCard = undefined;

    loadAllCards();

//        }
}

function editMultipleCardsCardToggle(el, e) {
    // shift-click operation
    if (e.shiftKey) {
        debug('SHIFT CLICK');
        if (lastClickedCard) {
            var startPos = $(".hover-layer").index(lastClickedCard) + 1;
            var endPos = $(".hover-layer").index($(el)) + 1;
            var subSelection = (startPos <= endPos) ? $(".hover-layer").slice(startPos, endPos) : $(".hover-layer").slice(endPos - 1, startPos);
            if (lastClickedCard.hasClass("selected")) {
                subSelection.each(function () {
                    $(this).addClass("selected");
                    $(this).parent().find("input").prop("checked", true);
                    editMultipleCheckSelection();
                });
            } else {
                subSelection.each(function () {
                    $(this).removeClass("selected");
                    $(this).parent().find("input").prop("checked", false);
                    editMultipleCheckSelection();
                });
            }
        }
    } else { // normal click
        $(el).toggleClass("selected");
        $(el).parent().find("input").prop("checked", !$(el).parent().find("input").prop("checked"));
    }
    lastClickedCard = $(el);
    $(".kartensatz-selected-cards-quantity").text($(".hover-layer.selected").length);
}



function editMultipleCheckSelection() {
    // when every card has been manually selected
    if ($(".hover-layer").length == $(".hover-layer.selected").length) {
        $(".edit-multiple-toolbar .custom-checkbox .fa-check").show();
        $(".edit-multiple-toolbar .custom-checkbox .fa-minus").hide();
        $(".edit-multiple-toolbar .custom-checkbox").addClass("deselect");
    } else if ($(".hover-layer.selected").length == 0) {
        $(".edit-multiple-toolbar .custom-checkbox .fa-check").hide();
        $(".edit-multiple-toolbar .custom-checkbox .fa-minus").hide();
        $(".edit-multiple-toolbar .custom-checkbox").removeClass("deselect");
        $(".edit-multiple-toolbar .custom-checkbox label").html(i18n.gettext("Select all"));
    } else {
        $(".edit-multiple-toolbar .custom-checkbox").addClass("deselect");
        $(".edit-multiple-toolbar .custom-checkbox .fa-check").hide();
        $(".edit-multiple-toolbar .custom-checkbox .fa-minus").show();
        $(".edit-multiple-toolbar .custom-checkbox label").html(i18n.gettext("Unselect all"));
    }
}



// MULTIPLE NEW CARDS

function multipleNewCardsCardFocus(el) {
    if (!($(el).hasClass("mce-content-body"))) {
        $(el).tinymce(editorConfig($(el)));
    }
}

function saveAllCards() {

    $(".js-save-all-cards-btn").prop('onclick', null).off('click');

    flashModal(i18n.gettext("Please wait..."));
    var boxId = $('#boxId').val();

    var data = "";

    var dataObj = [];
    var currentTemporaryId = new Date().getTime();
    
    var categoryIds = $("#import-cards-categories").val().split(",").filter(Boolean);

    $(".newcard").each(function () {

        var card = new Object();

        var mystr = $(this).attr('id');
        var myarr = mystr.split("-");
        var id = myarr[1];
//        var question;
//        var answer;

        card.question = escape(encodeURIComponent($("#newQuestion-" + id).html().trim()));
        card.answer = escape(encodeURIComponent($("#newAnswer-" + id).html().trim()));
//        if ($("#cardCategory-" + id).length > 0) {
//            card.categoryName = escape(encodeURIComponent($("#cardCategory-" + id).html().trim()));
//        }
        if ($("#cardCategoryNames-" + id).length > 0) {
            card.categoryNames = escape(encodeURIComponent($("#cardCategoryNames-" + id).html().trim()));
        }
        if (categoryIds.length > 0) {
            card.categories = categoryIds;
        }

        if ($(this).find(".mc-option-list").length > 0) {
            card.mChoiceData = [];
            $(this).find(".choice-container").each(function (i) {
                var mChoiceAnswerObj = Object();
                var mcChecked = $(this).find(".mchoice-checkbox").prop('checked');
                mChoiceAnswerObj.correct = mcChecked ? 1 : 0;
                mChoiceAnswerObj.mChoiceAnswer = escape(encodeURIComponent($(this).find(".mc-option-text").html().trim()));
                card.mChoiceData.push(mChoiceAnswerObj);
            });
        } else {
            card.mChoiceData = null;
        }
        card.keywords = [];
        if ($("#keywords-" + id).length > 0) {
            card.keywords = escape(encodeURIComponent($("#keywords-" + id).html().trim()));
        }
        card.tmpId = "tmpcard-" + currentTemporaryId;
        card.boxId = boxId;
        currentTemporaryId++;

        dataObj.push(card);

    });
    var nonce = getNonce();
    var createCardsData = JSON.stringify(dataObj);

    debug(createCardsData);
    flashModal('<div style="text-align:center;">' + i18n.gettext('Saving...') + '<br><br><img src="' + $("html").data("approot_images") + 'ajax-loader-2.gif">' + '</div>', 50000);

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "saveMultipleCards=" + createCardsData + "&nonce=" + nonce
    }).done(function (data) {
        var obj = JSON.parse(data.trim());

        var numOfIds = Object.keys(obj.cardIds).length;

        if (numOfIds > 1) {
            flashModal(numOfIds + " " + i18n.gettext("cards saved."));
        } else if (numOfIds == 1) {
            flashModal(i18n.gettext("Card saved."));
        } else if (numOfIds == 0) {
            flashModal(i18n.gettext("No card saved."));
        } else {
            flashModal(i18n.gettext("Finished."));
        }

        var errorHappened = false;
        var cardIds = obj.cardIds;
        for (var key in cardIds) {
            if (isNaN(cardIds[key]) || cardIds[key] == null) {
                flashModal("Fehler beim Abspeichern.");
                errorHappened = true;
            }
        }

        if (numOfIds === 0 || numOfIds < dataObj.length || errorHappened) {
            flashModal(i18n.gettext("Error while saving the card(s).") + " " + sprintf(i18n.gettext("%s of %s cards saved."), numOfIds, dataObj.length));
            sendError("Es wurden beim Import nicht alle Karten in Kartensatz " + boxId + " abgespeichert von: " + createCardsData);
        }

        loadPageToCenter("cardset-cards-" + boxId);
        if (history.pushState) {
            window.history.pushState(null, null, "cardset-cards-" + boxId);
            $('#pagehash').val("cardset-cards-" + boxId);
        }

    }).fail(function (xhr, status) {
        flashModal(i18n.gettext("Error while submitting the cards."));
        $(".js-save-all-cards-btn").click(function () {
            saveAllCards();
        });
    });

}



function showCardsetActions() {
    $(".js-floating-action-btn").hide();
    $(".js-menu-light-bg").show();
    disablePageScroll();
    setTimeout(function () {
        $(".js-menu-light-bg").on("click.closeMenuLightBg", closeCardsetOverlay);
    }, 10);
}

function closeCardsetOverlay() {
    $(".js-menu-light-bg").hide();
    $(".js-floating-action-btn").show();
    $(".js-menu-light-bg").off("click.closeMenuLightBg");
    reenablePageScroll();
}

function cardsetCardsSelectClick(el, e) {
    if ($(el).closest(".js-menu-light-bg").length) {
        e.stopPropagation();
    }
}

function checkIfOverlay(el) {
    if ($(el).closest(".js-menu-light-bg").length) {
        closeCardsetOverlay();
    }
}

function banAll() {
    var boxId = $('#boxId').val();
    $.post("cardset-colearners-" + boxId, {banAll: 1}).done(function(data) {refreshPage();});
}

function unBanAll() {
    var boxId = $('#boxId').val();
    $.post("cardset-colearners-" + boxId, {unBanAll: 1}).done(function(data) {refreshPage();});
}

// STUDY PALS MANAGEMENT

function studypalsManagement() {
    $(".js-studypals-management-btn").addClass("active").attr("disabled", "disabled");
    $(".js-studypals-management-explanation").css("opacity", 1).css("margin-left", 0);
    $(".js-studypals-management-explanation").show();
    var btnHeight = $(".js-studypals-management-done-btn").outerHeight();

    var $button = $(".js-studypals-management-done-btn");
    $button.css("bottom", -$button.outerHeight());
    $button.show();
    $button.animate({bottom: 0}, 200, "easeOutQuad");

    $(".js-user-display .pro-badge").hide();

    $(".js-user-display").each(function () {
        $(this).data("href", $(this).attr("href"));
        $(this).removeAttr("href");
        $(this).addClass("click");
        $(this).attr("onclick", "manageStudypalModal(this)");

        // add badges for moderators and creator
        var rank = null;
        if ($(this).data("creator") == 1) {
            rank = "creator";
        } else if ($(this).data("moderator") == 1) {
            rank = "moderator";
        }

        if (rank === "moderator") {
            var rankText = i18n.gettext("MODERATOR");
        } else if (rank === "creator") {
            var rankText = i18n.gettext("CREATOR")
        }

        if (rank) {
            $(this).find(".userpic").append(' \
                <div class="rank-badge ' + rank + '"> \
                    ' + rankText + ' \
                </div> \
            ');
        }

    });
}

function studypalsManagementDone() {
    $(".js-studypals-management-btn").removeClass("active").removeAttr("disabled");
    $(".js-studypals-management-explanation").css("opacity", 0).css("margin-left", "20px");
    $(".js-studypals-management-explanation").hide();

    var $button = $(".js-studypals-management-done-btn");
    $button.animate({bottom: -$button.outerHeight()}, 200, "easeOutQuad", function () {
        $(this).hide();
    });

    $(".js-user-display .pro-badge").show();
    $(".js-user-display .rank-badge").remove();

    $(".js-user-display").each(function () {
        $(this).attr("href", $(this).data("href"));
        $(this).removeAttr("data-href");
        $(this).removeClass("click");
        $(this).removeAttr("onclick");
    });
}


function refreshCategoriesPulldownList(boxId) {
    $.getJSON("kartensatz-karten-" + boxId + '?categories', function (data) {
        if ($(".js-show-category").length > 0) {
            $(".js-show-category").html('');
            $(".js-show-category").append('<option value="">' + i18n.gettext("All categories") + '</option>');
            $.each(data, function (key, val) {
                // val.id, val.name
                $(".js-show-category").append('<option value="' + val.id + '">' + val.name + '</option>');
            });
            $(".js-show-category").append('<option value="-1">' + i18n.gettext("Without category") + '</option>');
        }
    });
}

function getSelectedCardIdsAsArray() {
    var items = [];
    $(".hover-layer").filter(".selected").each(function (index) {
        debug(index + ": " + $(this).next().find('input').attr('id'));
        var mystr = $(this).next().find('input').attr('id');
        var myarr = mystr.split("-");
        var cardId = myarr[1];
        items.push(cardId);
    });
    return items;
}

function exportCsvSeparatorsShowHide() {
    if ($("#export-csv").is(":checked")) {
        $("#csvSeparators").show();
    } else {
        $("#csvSeparators").hide();
    }
}

function editCardsetTags() {

    $(".js-display-tags").hide();
    $(".js-display-tags-edit-button").hide();
    $(".js-edit-cardset-tags-block").show();

    var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
    $("#cardset-keywords").tagsInput({
        'autocomplete_url': 'tags',
        'autocomplete': {appendTo: "#tagSuggestions"},
        'defaultText': enterKeywordsString
                // 'height': '73px'
    });

    $("#cardset-keywords_tag").on('focusout', function () {
        debug("*** Triggering enter.");
        triggerEnter("#cardset-keywords_tag");
    });

    $(".ui-autocomplete-input").css("width", "340px");

    $(".tagsinput").css("width", "100%");
    $(".tagsinput").css("height", "150px");
    // $(".tagsinput").css("min-height", "200px");
    $(".tag").css("font-size", "1em");

}

function saveCardsetTags() {
    var tags = encodeURIComponent($(".cardset-keywords").val());
    var oldTags = encodeURIComponent($("#oldCardsetTags").val());

    var mystr = $(".page-headline").attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];

    $.ajax({
        type: "POST",
        url: "kartensatz-" + boxId,
        data: "&tags=" + tags + "&oldTags=" + oldTags,
        success: function (data) {
            shortInfo(i18n.gettext("Keywords of cardset updated."));
            $(".js-display-tags").show();
            $(".js-display-tags-edit-button").show();
            $(".js-edit-cardset-tags-block").hide();
            $(".js-display-tags").html(data);
        }
    });

}