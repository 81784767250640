function editNews(newsId) {

    $("#newsblock-" + newsId + " .newsTextBlock").tinymce(editorConfig($("#newsblock-" + newsId + " .newsTextBlock")));
    $("#newsblock-" + newsId + " .editButtons").hide();
    $("#newsblock-" + newsId + " .saveButtons").show();
    $("#newsblock-" + newsId + " .newsTitleInputBlock").show();
    $("#newsblock-" + newsId + " .module-hl").hide();

}

function deleteNews(newsId) {
    var check = window.confirm(i18n.gettext("Really delete this news entry?"));
    if (check == true) {
        $.ajax({
            type: "GET",
            url: "news",
            data: "deleteNews=" + newsId
        }).done(function (data) {
            var dataArr = JSON.parse(data);
            flashModal(dataArr.answer);
            refreshPage("news");
        });
    }
}

function saveEditedNews(newsId) {
    var newsTitle = encodeURIComponent($("#newsblock-" + newsId + " .newsTitleEdit").val());
    var newsText = encodeURIComponent($("#newsblock-" + newsId + " .newsTextBlock").html());
    $.ajax({
        type: "POST",
        url: "news",
        data: "newsId=" + newsId + "&newsTitle=" + newsTitle + "&newsText=" + newsText
    }).done(function (data) {
        var dataArr = JSON.parse(data);
        flashModal(dataArr.answer);
        refreshPage("news");
    });
}