var lastClickedClass = '';
var lastClickedId = '';

if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
    };
}

if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}

function getCurrentTimestampInSeconds() {
    return Math.floor(Date.now() / 1000);
}

function showAjaxLoader(identifier, marginTop) {
    marginTop = (typeof marginTop === "undefined") ? "0px" : marginTop;
    // var loaderCode = '<img src="' + approot_images + 'ajax-loader.png" style="top: ' + marginTop + ';" class="ajax-loader"/>';
    var loaderCode = '<img src="' + $("html").data("approot_images") + 'ajax-loader-2.gif" style="top: ' + marginTop + ';" class="ajax-loader"/>';
    $(identifier).append(loaderCode);
}

function hideAjaxLoader() {
    $(".ajax-loader").remove();
}

function getUrlHash() {
    var approot = $("html").data("approot");
    var urlstring = document.URL;
    var urlhash = urlstring.split(approot)[1];
    return urlhash;
}

function checkHash(callback) {

    var urlhash = getUrlHash();
    var pagehash = $('#pagehash').val();

    if (urlhash != null && urlhash.substring(0, 7) == 'cardref') {
        var cardId = urlhash.split('_')[1];
        var url = $("html").data('approot') + 'cardref/' + cardId + "?slim=true";
        // loadPageToCenter(url);
    } else {
        debug("Is there any TinyMCE editor open?");
        if ($(".mce-content-body").length > 0 && $(".mce-content-body").is(":visible")) {
            debug("YES!");
            // For IE and Firefox prior to version 4
            var a = confirm(i18n.gettext('An input field is still open. Do you really want to leave the site?'));
            if (a == true) {
                if ($(".multiple-new-cards-container").is(":visible")) {
                    cancelCreateCard($(".cancel-create-card-btn"), true);
                }
                loadPageToCenter(urlhash, callback);
                // $('#pagehash').val(urlhash);
            }
        } else {
            debug("NO!");
            if ((urlhash != null) && (urlhash != pagehash)) {
                debug('urlhash is unequal to pagehash: ' + urlhash + ' is not equal to ' + pagehash);
                loadPageToCenter(urlhash, callback);
                // $('#pagehash').val(urlhash);
            }
        }
    }

}

function refreshPage(callback) {
    var urlhash = getUrlHash();
    var pagehash = $('#pagehash').val();
    if (urlhash == pagehash) {
        loadPageToCenter(pagehash, callback);
    } else {
        loadPageByAjax(pagehash, callback);
    }
}

function loadPageByPost(url) {
    window.history.pushState(null, null, url);
    loadPageToCenter(url, null, "POST");
}

function loadPageByAjax(page, callback) {
//    setTimeout(function () {
//        closeModal();
//    }, 1000);
    debug("We load the page by Ajax: " + page);
    if (page.trim() == '') {
        debug("page is empty string!");
        if ($("html").data("loggedin") == "1") {
            page = 'main';
        } else {
            page = 'landing';
        }
    } else {
        debug("page is not empty string (loadPageByAjax).");
    }
    
    if (typeof (window.history.pushState) === 'function') {
        window.history.pushState(null, null, page);
        checkHash(callback);
    } else {
        loadPageToCenter(page, callback);
    }
}

var repeticoAjaxRequestRunning = false;
var loadPageToCenterProgress = null;
var currentlyLoadedPage = null;

function loadPageToCenter(href, callback, method) {
    
    var httpLoadingMethod = "GET";
    if (typeof method === 'undefined') {
        httpLoadingMethod = "GET";
    } else {
        httpLoadingMethod = method;
    }

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }

    closeFlashModalIfOld();

    if (href.trim() == '') {
        debug("page is empty string!");
        if ($("html").data("loggedin") == "1") {
            href = 'main';
        } else {
            href = 'landing';
        }
    } else {
        debug("page is not empty string (loadPageToCenter).");
    }
    $('#center').fadeTo(200, 0.75, 'swing');

    if (typeof lastClickedId !== 'undefined') {
        debug("### clicked id is: " + lastClickedId);
    }
    if (typeof lastClickedClass !== 'undefined') {
        debug("### clicked class is: " + lastClickedClass);
    }

    $(".modal.specific").remove();

//    if ($("#userKey").val() != $("#userKeyNew").val()) {
//        sendError("Userkey changed from " + $("#userKey").val() + " to " + $("#userKeyNew").val());
//        $("#userKey").val($("#userKeyNew").val());
//    }

    if ($("#appVersionNew").length > 0 && $("#appVersion").length > 0 && ($("#appVersionNew").val() != $("#appVersion").val())) {
        // flashModal('App-Reload');
        window.location.href = href;
    } else {

        var currentTimeStamp = getCurrentTimestampInSeconds();
        if (!(isNaN(repeticoAjaxRequestRunning)) && (currentTimeStamp - repeticoAjaxRequestRunning < 30)) {
            debug("--- Ajax request is still running. ---");
            if ((currentTimeStamp - repeticoAjaxRequestRunning) > 10) {
                var interval = 30 - (currentTimeStamp - repeticoAjaxRequestRunning);
                flashModal(sprintf(i18n.gettext("Waiting %s seconds if request of page %s will finish."), interval, '&#34;' + href + '&#34;'));
            }
            return;
        }

        debug("--- Ajax request starts. ---");
        repeticoAjaxRequestRunning = getCurrentTimestampInSeconds();
        
        if (loadPageToCenterProgress) {
            debug("--- Aborting previous ajax request. ---");
            loadPageToCenterProgress.abort();
        }

        loadPageToCenterProgress = $.ajax({
            type: httpLoadingMethod,
            url: href,
            data: "dynReq=1",
        }).done(function (data) {
            
            currentlyLoadedPage = href;
            if (href == "main") {
                $('#pagehash').val("");
            } else {
                $('#pagehash').val(href);
            }
            
            $('#center').finish();

//            try {
            debug("Lade die Inhalte...");
            $('#center').html(data);
            debug("Inhalte geladen. Fuehre weitere Funktionen aus ...");
            $("html").data("loadedbyajax", "true");
//            } catch (e) {
//                debug(e.message);
//            }

            // setTimeout(function() {
            if ($("#hideTopAd").length > 0) {
                if ($("#hideTopAd").val() == 0 && (!($(".ad-banner-top-container").is(":visible")) || !($(".ad-banner-top").is(":visible")))) {
                    // PROBLEM: This is also the case in mobile view.
                    // flashModal(i18n.gettext("Bitte deaktivieren Sie Ihren Werbeblocker oder upgraden Sie auf Repetico PRO! Danke."));
                }
            }
            // },1000);

            $('#center').fadeTo(100, 1.0, 'swing');

            debug("Wieder auf 1.0 gefadet!");
            if (href.indexOf("#") != -1) {
                var index = href.indexOf("#");
                var scrollMark = href.substr(index + 1);
                debug("ScrollMark: " + scrollMark);
                if (scrollMark != 'noscroll') {
                    try {
                        var elmnt = document.getElementById(scrollMark);
                        elmnt.scrollIntoView(true); // Top
                    } catch (e) {
                        debug("Scrolling to top failed.");
                        window.scrollTo(0, 0);
                    }
                }
            } else {
                window.scrollTo(0, 0);
            }
            // window.history.pushState('object', 'Title', href);

            // needs to be reset every time or nicescroll won't initialize!!! WHY?
            var niceScrollConfig = {
                cursorwidth: 10,
                cursorborder: "none",
                cursorborderradius: 0,
                horizrailenabled: false,
                autohidemode: false
            };

            if ($(".activity-bar").length) {
                if ($(".activity-bar").is(":visible")) {
                    $(".activity-bar-toggle").addClass("active");
                }

                $(".activity-bar-content").niceScroll(niceScrollConfig);
                $(".activity-bar-content").getNiceScroll().show();
                $(".activity-bar-content").getNiceScroll().resize();

                // for small layout, hide activity-bar on every page-load
                if (!mqMinWidth1340.matches) {
                    $(".activity-bar").addClass("hidden");
                    // prevent FOUC, have activity-bar display: none, before hidden class is assigned
                    $(".activity-bar").css("display", "block");
                }
            }

            if ($(".js-shopping-cart-bar").length) {
                $(".js-shopping-cart-bar-content").niceScroll(niceScrollConfig);
                $(".js-shopping-cart-bar-content").getNiceScroll().show();
                $(".js-shopping-cart-bar-content").getNiceScroll().resize();
            }

            $(".notifications-content").niceScroll(niceScrollConfig);
            $(".notifications-content").getNiceScroll().resize();
            $(".new-discussion-posts-content").niceScroll(niceScrollConfig);
            $(".new-discussion-posts-content").getNiceScroll().resize();

            // position top-bar-popups over content, when activity bar has been clicked away in wide layout
            if ($(".page-container").outerWidth() == 984) {
                $(".notifications").addClass("content-overlay");
                $(".new-discussion-posts").addClass("content-overlay");
            }

            if ($("#pendingMessage").length > 0) {
                var message = $("#pendingMessage").html().trim();
                if (message.length > 0) {
                    generalPurposeModal(message);
                    $("#pendingMessage").html('');
                }
            }

//            var page = addHtmlPageClass(href);
//            executePageFn(page);

            /*
             * SOME OTHER STUFF // get new page name from html class var page =
             * $.grep($("html").attr("class").split(" "), function(v,i) { return
             * v.indexOf('page-') === 0; }).join(); // change string to
             * camelCase page = page.replace( /-([a-z])/ig, function( all,
             * letter ) { return letter.toUpperCase(); });
             *
             */
            refreshActivityBarInit();

            autosize($('textarea'));
            if (inIframe()) {
                // changeToIframeView();
            }

            if (typeof callback == 'function') {
                callback();
            }



            if ($("#hideTopAd").length > 0) {
                var adBlockerDetected = false;
                if ($("#hideTopAd").val() == 0 && $(".ad-banner-top-container").length > 0 && !($(".ad-banner-top-container").is(":visible"))) {
                    adBlockerDetected = true;
                }
                if ($("#hideTopAd").val() == 0 && $(".ad-banner-top").length > 0 && !($(".ad-banner-top").is(":visible"))) {
                    adBlockerDetected = true;
                }
                /*
                 if ($("#hideTopAd").val() == 0 && $(".ad-banner-top").children().first().children().length == 0) {
                 adBlockerDetected = true;
                 }
                 */
                if (adBlockerDetected) {
                    /*
                     debug('Bitte deaktivieren Sie den Werbeblocker.');
                     flashModal(i18n.gettext("Bitte deaktivieren Sie Ihren Werbeblocker oder upgraden Sie auf Repetico PRO! Danke."));
                     */
                }
            }

            if ($("#pendingMessage").length > 0) {
                var message = $("#pendingMessage").val().trim();
                if (message.length > 0) {
                    generalPurposeModal(message);
                }
            }

            var downtime = $("#downtime").val();
            if (!isNaN(downtime) && downtime > 0 && !$(".downtime-warning").is(":visible")) {
                var deadline = new Date(downtime * 1000);
                $(".downtime-warning").show();
                initializeClock('clockdiv', deadline);
            }

            if (typeof window.dataLayer !== 'undefined' && window.dataLayer instanceof Array) {
                debug("Pushing page load event to Google Analytics.");
                window.dataLayer.push({
                    'event': 'virtualPageView',
                    'virtualUrl': '/' + href,
                    'virtualTitle': 'My page title'
                });
            }

            // loadAds();

        }).fail(function (xhr, status) {
            $('#center').fadeTo(300, 1.0, 'swing');
            if (xhr != null) {
                debug("xhr.status: " + xhr.status);
                if (xhr.status == "404" || xhr.status == 404) {
                    repeticoAjaxRequestRunning = false;
//                    currentlyLoadedPage = null;
                    $('#center').fadeTo(300, 1.0, 'swing');
//                    var approot = $("html").data("approot");
//                    debug("Url of request was: " + xhr.url);
//                    debug("Approot is: " + approot);
//                    alert(i18n.gettext("Page not found: ") + xhr.url);
                    debug("Going back in history at ajax.js, line 362");
                    window.history.back();
//                    window.history.pushState(null, null, currentlyLoadedPage);
                    generalPurposeModal(i18n.gettext("Page not found: ") + "<b>" + href + "</b>");
//                    if (xhr.url.indexOf(approot) !== -1) {
//                        flashModal(i18n.gettext("Page not found!"));
//                        throw new Error('Page not found: --- Href: ' + href + ' --- Id: ' + lastClickedId + ' --- Class: ' + lastClickedClass);
//                    }
                } else if (xhr.status == "500" || xhr.status == 500) {
                    repeticoAjaxRequestRunning = false;
//                    currentlyLoadedPage = null;
                    debug("Trying to push the following state to the window history: " + currentlyLoadedPage);
                    debug("Going back in history at ajax.js, line 374");
                    window.history.back();
//                    window.history.pushState(null, null, currentlyLoadedPage);
                    flashModal(i18n.gettext('Page could not be loaded. Please try again later!'), 3000);
                    $('#center').fadeTo(300, 1.0, 'swing');
                } else if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        debug("Retrying to load URL: " + href);
                        //try again
                        // shortInfo(i18n.gettext('Wiederholung der Server-Anfrage...'));
                        $.ajax(this);
                        // sendError('Retry #' + this.tryCount + ' loading page ' + href);
                        return;
                    } else {
                        repeticoAjaxRequestRunning = false;
//                        currentlyLoadedPage = null;
                        debug("Going back in history at ajax.js, line 391");
                        window.history.back();
                        flashModal(sprintf(i18n.gettext('Page %s could not be loaded. Please try again later!'), '&#34;' + href + '&#34;'), 3000);
                        // sendError('No answer from server after ' + this.tryCount + ' tries for: ' + href);
                        $('#center').fadeTo(300, 1.0, 'swing');
                    }
                    return;
                }
            }
        }).complete(function (xhr, status) {
            repeticoAjaxRequestRunning = false;
//            currentlyLoadedPage = null;
            debug("--- Ajax request finished. ---");
        });

    }
}

function loadUrlToId(url, data, jqueryId) {
    $.ajax({
        type: "POST",
        url: url,
        data: data
    }).done(function (data) {
        $(jqueryId).html(data);
    }).fail(function (xhr, status) {

    });
}

// ADD PAGE SPECIFIC CLASS TO HTML TAG
function addHtmlPageClass(href) {

    var page = href;

    if ($('#pageName').length > 0) {
        page = $('#pageName').val();
    }

    if (page == '') {
        if ($("html").data("loggedin") == "1") {
            page = 'main';
        } else {
            page = 'landing';
        }
    }

    // check for number (ID) in href
    var numInStr = /\d/.exec(page);
    // if there is a number in href, remove it
    if (numInStr) {
        page = page.slice(0, numInStr.index - 1);
    }
    // remove possible parameters from string (only happens with safari?
    var qm = page.indexOf("?");
    if (qm !== -1) {
        page = page.slice(0, qm);
    }
    // addHtmlPageClass: remove old page class from html tag
    var prefix = "page-";
    var classes = $("html").attr("class").split(" ").filter(function (c) {
        return c.lastIndexOf(prefix, 0) !== 0;
    });
    $("html").attr("class", classes.join(" "));
    // add new page- class to html tag
    $("html").addClass('page-' + page);

    // debug("update html page class to page-" + page);
    return page;
}

$(document).ready(function () {

    var approot = $("html").data("approot");

    if (typeof (window.history.pushState) === 'function') {
        $(document).on('click', 'a', function (e) {
            // only if link is NOT a mailto link AND doesn't open in new window!

            if (!$(this).hasClass("mailto") && $(this).attr("target") !== "_blank" && !e.ctrlKey) {

                e.preventDefault();
                lastClickedId = $(this).attr("id");
                lastClickedClass = $(this).attr("class");
                var href = $(this).attr('href');

//                var doit = true;
//                if ($(".mce-content-body").length > 0 && $(".mce-content-body").is(":visible")) {
//                    doit = confirm(i18n.gettext("An input field is still open. Do you really want to leave the site?"));
//                }

                // debug("Wir laden jetzt die Seite.");

//                if (doit) {

                // wenn aktuell beim Erstellen von neuen Karten: Fuehre cancelCreateCard aus.
//                    if ($(".multiple-new-cards-container").is(":visible")) {
//                        cancelCreateCard($(".cancel-create-card-btn"), true);
//                    }

                if (href == undefined) {
                    return false;
                } else {
                    if (href.startsWith('mailto:')) {
                        window.location = href;
                    } else if ((href.startsWith('https://') || (href.startsWith('http://'))) && !href.startsWith(approot)) {
                        window.open(href, '_blank');
                    } else if (this.hash.indexOf('#cardref_') == 0) {
                        // debug("Show popup of card " + this.hash.substring(9));
                        // TODO

                        $("#card-modal").modal();
                        showAjaxLoader('#card-modal-content', '70px');
                        var cardId = this.hash.substring(9);
                        // set it to width 800px, margin-left -400px

                        $.ajax({
                            type: "POST",
                            url: "karte-" + cardId,
                            data: "module=card-core&cardref=1",
                            success: function (data) {
                                $('#card-modal-content').html(data);
                            }
                        });

                    } else if (href != 'logout' && !($("html").data("loggedin") != "1" && href == approot) && history.pushState) {
                        loadPageByAjax(href);
                    } else {
                        window.location = href;
                    }
                }
//                }
            }

        });
    }

    function myPopStateHandler() {
        // debug("pop state!");
//        var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
//        var is_safari = navigator.userAgent.indexOf("Safari") > -1;
//        if ((is_chrome) && (is_safari)) {
//            is_safari = false;
//        }
//
//        if (is_safari) {
//            debug("This is SAFARI!");
//            var popped = ('state' in window.history && window.history.state !== null), initialURL = location.href;
//            if (popped) {
//                debug("popped! i check the hash.");
//                checkHash();
//            } else {
//                debug("not popped!")
//            }
//        } else {
//            checkHash();
//        }

    }

    if (history.pushState) {
        $(window).on("popstate", checkHash);
    } else {
        // Event listener to cature address bar updates with hashes.
        window.attachEvent("onhashchange", function (e) {
            checkHash();
        });
    }

//	if (!is_safari) {
//
//	} else {
//		debug("I am Safari.");
//		setTimeout( function() {
//		  window.addEventListener( 'popstate', myPopStateHandler, false );
//		}, 500 );
//	}

    function get_unixtime_ms() {
        return new Date().getTime();
    }

    $.ajaxSetup({
        tryCount: 0,
        retryLimit: 3,
        retryAfter: 7000,
//        data: {
//            sharedsecret: $('#sharedsecret').val()
//        },
        beforeSend: function (jqXHR, settings) {
            jqXHR.url = settings.url;
            return true;
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                debug("xhr.status: " + xhr.status);
                if (xhr.status == "404" || xhr.status == 404) {
                    repeticoAjaxRequestRunning = false;
                    $('#center').fadeTo(300, 1.0, 'swing');
                    var approot = $("html").data("approot");
                    if (xhr.url.indexOf(approot) !== -1) {
                        flashModal(404 + ": " + i18n.gettext("Page not found."));
                        throw new Error('Target for request not found: --- Href: ' + xhr.url + ' --- Id: ' + lastClickedId + ' --- Class: ' + lastClickedClass);
                    }
                } else if (xhr.status == "302") {
                    var approot = $("html").data("approot");
                    repeticoAjaxRequestRunning = false;
                    window.location.href = approot;
                } else if (xhr.status == "502") {
                    repeticoAjaxRequestRunning = false;
                    debug("Going back in history at ajax.js, line 601");
                    window.history.back();
                    generalPurposeModal(i18n.gettext("The server is temporarily unreachable, which means that it is probably down for maintenance and will be back online soon."), i18n.gettext("Please wait and try again..."));
                } else if (status == 'timeout') {
                    debug("There was a timeout. Retry?");
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        debug("I can retry, because " + this.tryCount + " is smaller than " + this.retryLimit);
                        //try again
                        // setTimeout(function () {
                        // debug("Running retry...");
                            $.ajax(this);
                        //}, this.retryAfter);
                        // shortInfo(i18n.gettext('Wiederholung der Server-Anfrage...'));
                        // sendError('Retry #' + this.tryCount + ' for: ' + xhr.url);
                    } else {
                        debug("I did not get an answer from the server.");
                        repeticoAjaxRequestRunning = false;
                        debug("Going back in history at ajax.js, line 619");
                        window.history.back();
//                        window.history.pushState(null, null, currentlyLoadedPage);
                        shortInfo(i18n.gettext('No answer received from server.'), 3000);
                        sendError('No answer from server after ' + this.tryCount + ' tries for: ' + xhr.url);
                    }
                    return;
                } else if (status == 'error') {
                    repeticoAjaxRequestRunning = false;
//                    window.history.pushState(null, null, currentlyLoadedPage);
                    debug("Going back in history at ajax.js, line 629");
                    // window.history.back();
                    flashModal(i18n.gettext("Page could not be loaded because of an error."));
                    sendError("Error while loading page: " + xhr.url + " " + xhr.status + ", " + status + ", " + text);
                } else {
                    repeticoAjaxRequestRunning = false;
                    debug("An unknown AJAX-Error occured.");
                    debug("Going back in history at ajax.js, line 636");
                    // window.history.back();
                    flashModal(i18n.gettext("Page could not be loaded."));
                    sendError("Unknown error while loading page: " + xhr.url + " " + xhr.status + ", " + status + ", " + text);
                }
            }
        }
    });

});

function getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}

function initializeClock(id, endtime) {

    // Use for instance https://www.gaijin.at/olsutc.php for timestamp conversion

    var clock = document.getElementById(id);
    var daysSpan = clock.querySelector('.days');
    var hoursSpan = clock.querySelector('.hours');
    var minutesSpan = clock.querySelector('.minutes');
    var secondsSpan = clock.querySelector('.seconds');

    function updateClock() {
        var t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

        if (t.total <= 900 * 1000) {
            $(".downtime-warning").show();
        } else {
            $(".downtime-warning").hide();
        }

        if (t.total <= 300 * 1000) {
            $(".downtime-warning").addClass("blink");

            if (t.total <= 0) {
                $(".js-downtime-warning-text").html("<br>" + i18n.gettext("Please wait until the pause ended."));
                $(".js-downtime-warning-clock").html('<span style="font-weight: bold; color: red;">' + i18n.gettext("Downtime NOW!") + '</span>');
                $(".downtime-warning").css("margin-left", "-180px");
                $(".downtime-warning").css("width", "360px");
                var height = $(".downtime-warning").height();
                $(".downtime-warning").css("margin-top", "-" + height / 2 + "px");
                $(".downtime-warning").css("position", "fixed");
                $(".downtime-warning").css("top", "50%");
                $(".downtime-warning").css("left", "50%");
                if (t.total % 60000 == 0) {
                    var approot = $("html").data("approot");
                    var downtime = 0;
                    debug("Downloading " + approot + "downtime.txt");
                    let ms = Date.now();
                    $.ajax({
                        type: "GET",
                        url: approot + "downtime.txt?version=" + ms
                    }).done(function (data) {
                        downtime = parseInt(data);
                        debug("Downloaded downtime is: " + downtime);
                        if (downtime === 0) {
                            // $(".downtime-warning").hide();
                            $(".downtime-warning").removeClass("blink");
                            $(".downtime-warning").addClass("click");
                            $(".js-downtime-warning-text").html("");
                            $(".js-downtime-warning-clock").html('<span style="font-weight: bold; color: green;">'
                                    + i18n.gettext("Pause has ended. Click here to continue!")
                                    + '</span>');
                            $(".downtime-warning").attr("onclick", '$(".downtime-warning").hide();');
                            clearInterval(timeinterval);
                        } else {
                            debug("Downtime is not yet over.");
                        }
                    }).fail(function (xhr, status) {
                        debug("Could not download downtime.txt");
                        downtime = 0;
                        clearInterval(timeinterval);
                    }).complete();
                }
                // clearInterval(timeinterval);
            } else {
                $(".js-downtime-warning-text").html("<br>" + i18n.gettext("Please save <b>NOW</b> if you are editing anything."));
            }

//            if (t.total > 1800 * 1000) {
//                // halbe Stunde nach Downtime-Start
//                $(".downtime-warning").hide();
//            }

        }

    }

    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
}

function getCurrentDowntimeFile() {
    var approot = $("html").data("approot");
    var downtime = 0;
    debug("Downloading " + approot + "downtime.txt");
    let ms = Date.now();
    $.ajax({
        type: "GET",
        url: approot + "downtime.txt?version=" + ms
    }).done(function (data) {
        downtime = parseInt(data);
        debug("Downloaded downtime is: " + downtime);
    }).fail(function (xhr, status) {
        debug("Could not download downtime.txt");
        downtime = 0;
    }).complete();
    return downtime;
}

window.onbeforeunload = function (e) {
    e = e || window.event;
    if ($(".mce-content-body").length > 0 && $(".mce-content-body").is(":visible")) {
        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = i18n.gettext('An input field is still open.');
        }
        // For Safari
        return i18n.gettext('An input field is still open.');
    }
//    else if ($("#pagehash").val().startsWith("study") || $("#pagehash").val().startsWith("lernen")) {
//        if (e) {
//            e.returnValue = i18n.gettext('Do you really want to leave the page?');
//        }
//        return i18n.gettext('Do you really want to leave the page?');
//    }
};

window.onunload = function (e) {
    debug("UNLOADING WINDOW...");
    if ($(".multiple-new-cards-container").is(":visible")) {
        cancelCreateCard($(".cancel-create-card-btn"), true);
    }
    return false;
}

if (!window.__gCrWeb)
    window['__gCrWeb'] = {};
