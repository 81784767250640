// open login modal button
function loginModal(actionAfterLogin) {
    // if actionAfterLogin is defined, then do it after logging in.
    actionAfterLogin = typeof actionAfterLogin !== 'undefined' ? actionAfterLogin : "";
    $("#login-modal #actionAfterLogin").val(actionAfterLogin);
    
    $("#login-modal").modal();
    setTimeout(function () {
        $("#login-modal").find("input:first").focus();
    }, 0);
}

function loginFormKeypress(elem, event, userNameFieldIdentifier, passwordFieldIdentifier) {
    userNameFieldIdentifier = (typeof userNameFieldIdentifier === "undefined") ? "#loginusername" : userNameFieldIdentifier;
    passwordFieldIdentifier = (typeof passwordFieldIdentifier === "undefined") ? "#loginuserpass" : passwordFieldIdentifier;
    if (event.keyCode == 13) {
        console.log("Login form key press with field identifiers: " + userNameFieldIdentifier + ", " + passwordFieldIdentifier);
        login(userNameFieldIdentifier, passwordFieldIdentifier, elem);
    }
}

function login(userNameFieldIdentifier, passwordFieldIdentifier, elem) {
    
    if (userNameFieldIdentifier == "undefined" || passwordFieldIdentifier == "undefined" || typeof userNameFieldIdentifier === "undefined" || typeof passwordFieldIdentifier === "undefined") {
        debug("No identifiers given for user-name-field or password-field.");
        return;
    }
    
    debug("Executing login with field identifiers: " + userNameFieldIdentifier + ", " + passwordFieldIdentifier);
    
    if ($(".login-animation").length > 0) {
        $(".login-animation").show();
    }
    
    $(elem).attr("disabled","disabled");
    if ($(elem).is("input")) {
        $(elem).removeAttr("onkeypress");
    }
    
    var userName = encodeURIComponent($(userNameFieldIdentifier).val());
    var password = encodeURIComponent($(passwordFieldIdentifier).val());
    var stayLoggedIn = $("#stayLoggedIn").is(':checked');
    var couponcodeGoto = '';
    if ($('#couponcode').length > 0) {
        // couponcodeGoto = 'checkout?couponcode=' + $('#couponcode').val();
        couponcodeGoto = 'coupon?couponCodeDefault=' + $('#couponcode').val();
    }
    // var approot = $("#approot").val();
    var invitation = '';
    if ($("#invitation").length > 0) {
        invitation = $("#invitation").val();
    }

    $.ajax({
        type: "POST",
        url: "landing",
        data: "loginusername=" + userName + "&loginuserpass=" + password + "&stayLoggedIn=" + stayLoggedIn + "&invitation=" + invitation,
        success: function (data) {
            try {
                var obj = JSON.parse(data.trim());
                if (obj.success === true) {
                    if ($("#login-modal.current").length > 0 && $("#login-modal.current").is(":visible")) {
                        $("#login-modal.current #login-message").html("");
                    }
                    if ($("#actionAfterLogin").length > 0 && $("#actionAfterLogin").val() != "") {
                        var nameOfFunction = $("#actionAfterLogin").val();
                        window[nameOfFunction]();
                    } else {
                        if ($('#courseOneTimeId').length > 0 && $('#courseOneTimePassword').length > 0) {
                            joinCourseByOneTimeData(function() {
                                window.location = 'welcome';
                            });
                        } else if ($('#courseId').length > 0 && $('#courseCode').length > 0) {
                            window.location = 'course-join?courseId=' + $('#courseId').val() + '&courseCode=' + $('#courseCode').val();
                        } else if ($('#courseId').length > 0 && $('#coursePassword').length > 0) {
                            window.location = 'course-join?courseId=' + $('#courseId').val() + '&coursePassword=' + $('#coursePassword').val();
                        } else {
                            if (couponcodeGoto != '') {
                                window.location = couponcodeGoto;
                            } else {
                                window.location = obj.goto;
                            }
                        }
                    }
                } else {
                    if ($(".login-animation").length > 0) {
                        $(".login-animation").hide();
                    }
                    if ($("#login-modal.current").length > 0 && $("#login-modal.current").is(":visible")) {
                        $(".js-log-in-btn").effect( "shake" );
                        $("#login-modal.current #login-message").html("<div style=\"margin-top:5px;margin-bottom:5px;font-size:0.9em;color:red;\">" 
                                + obj.message
//                                + i18n.gettext("Username or password is wrong.") 
//                                + " "
//                                + i18n.gettext("Please note the difference between upper and lower case letters in your password!")
                                + "</div>");
                    } else {
                        // flashModal(i18n.gettext("Login failed."), "default", "error");
                        flashModal(obj.message);
                    }
                }
            } catch (e) {
                if ($(".login-animation").length > 0) {
                    $(".login-animation").hide();
                }
                flashModal(i18n.gettext("Login not successful."), "default", "error");
                debug("*** Error: " + e.message);
                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
                return false;
            }
        }, complete: function (data) {
            $(elem).removeAttr('disabled');
            if ($(elem).is("input")) {
                $(elem).attr("onkeypress", "loginFormKeypress(this, event, '" + userNameFieldIdentifier + "', '" + passwordFieldIdentifier + "')");
            }
            return false;
        }
    });
    
    return false;
}

function loginEdiscio() {
    var userName = encodeURIComponent($('#username').val()) + '_ediscio';
    var password = encodeURIComponent($('#password').val());
    $.ajax({
        type: "POST",
        url: "landing",
        data: "loginusername=" + userName + "&loginuserpass=" + password,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            if (obj.success === true) {
                window.location = obj.goto;
            } else {
                flashModal(i18n.gettext("Login failed."), "default", "error");
            }
        }
    });
}
