// execute page specific function on ajax page load if there is one
function executePageFn(page) {
    // change page string to camelCase
    var fname = page.replace(/-([a-z])/ig, function (all, letter) {
        return letter.toUpperCase();
    });

    debug("Executing function: " + fname);

    // execute function if it exists
    if ($.isFunction(window['pageFn' + fname])) {
        debug('execute page specific function for page', page);
        window['pageFn' + fname]();
    }
}

// PAGE SPECIFIC FUNCTIONS

function pageFnmain() {
    // create card set legend donut chart if it exists
    if ($(".card-set-legend").length) {
        createDonutChart($(".card-set-legend").find(".donut-chart"));
        $(".card-set-legend .progress-bar").animate({width: $(".card-set-legend .progress-bar").data('progress') + '%'}, 1000, 'easeOutQuad');
    }

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    if ($("#effort-chart").length > 0) {
        createEffortChart($("#effort-chart").find("canvas"));
    }
    if ($("#known-cards-chart").length > 0) {
        createKnownCardsChart($("#known-cards-chart").find("canvas"));
    }
    if ($("#cards-status-development-chart").length > 0) {
        createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
    }

    // enableDocumentSelection(true);
    loadStatistics();



    executeTour('main');
    // loadColearners();
}

function pageFnkartensaetze() {
    // create card set legend donut chart if it exists
    if ($(".card-set-legend").length) {
        createDonutChart($(".card-set-legend").find(".donut-chart"));
        $(".card-set-legend .progress-bar").animate({width: $(".card-set-legend .progress-bar").data('progress') + '%'}, 1000, 'easeOutQuad');
    }
    // enableDocumentSelection(true);
    loadStatistics();
    // loadColearners();
}

function pageFncardsets() {
    pageFnkartensaetze();
}

function pageFnkartensatzStatistik() {
    debug("executing kartensatzStatistik");
    touchFriendlyHover($(".js-learn-options-btn"));
    // temporary fix, if statements because non-premium users don't have these stats right now
    if ($("#cards-status-development-chart").length > 0) {
        createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
    }
    if ($("#effort-chart").length > 0) {
        createEffortChart($("#effort-chart").find("canvas"));
    }
    if ($("#known-cards-chart").length > 0) {
        createKnownCardsChart($("#known-cards-chart").find("canvas"));
    }
    createDonutChart($(".donut-chart.big"));
    $(".bar-chart .bar").each(function () {
        $(this).animate({width: $(this).data('width') + '%'}, 1700, 'easeOutQuad');
    });
}

function pageFncardsetStatistics() {
    pageFnkartensatzStatistik();
}

function pageFnstatistik() {
    // temporary fix, if statements because non-premium users don't have these stats right now
    if ($("#cards-status-development-chart").length > 0) {
        createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
    }
    if ($("#effort-chart").length > 0) {
        createEffortChart($("#effort-chart").find("canvas"));
    }
    if ($("#known-cards-chart").length > 0) {
        createKnownCardsChart($("#known-cards-chart").find("canvas"));
    }
    createDonutChart($(".donut-chart.big"));
    $(".bar-chart .bar").each(function () {
        $(this).animate({width: $(this).data('width') + '%'}, 1700, 'easeOutQuad');
    });
}

function pageFnstatistics() {
    pageFnstatistik();
}

function pageFnkpi() {
    // createKnownCardsChart($("#activeusers-chart").find("canvas"));
    $(".js-kpi-chart").each(function () {
        createKnownCardsChart($(this).find("canvas"));
    });
    $(".js-kpi-dual-chart").each(function () {
        createEffortChart($(this).find("canvas"));
    });
    $(".js-kpi-triple-chart").each(function () {
        createTripleChart($(this).find("canvas"));
    });
}

function pageFnlernstatistik() {
    createDonutChart($(".donut-chart.big"));
    wat();
}

function pageFnstudyStatistics() {
    pageFnlernstatistik();
}

function pageFnlernen() {
    // autosize($('textarea')); // for my-answer textarea
    if (getCookie('focusMyAnswerText') != 'false') {
        $(".my-answer").focus();
    }
    equalizeCardTopTextHeight($(".learn-card"));
    if ($(".question-text").length > 0 && $(".question-text").hasClass('disableCopying')) {
        enableDocumentSelection(false);
    }
//    else {
//        enableDocumentSelection(true);
//    }
    touchFriendlyHover($(".page-lernen"), ".choice-container");
    
    $('.card img').click(function () {
        var loc = $(this).attr("src");
        debug("Opening location in new tab or window: " + loc);
        window.open(loc, '_blank');
    });
    
    $('.correct-answer img').click(function () {
        var loc = $(this).attr("src");
        debug("Opening location in new tab or window: " + loc);
        window.open(loc, '_blank');
    });
}

function pageFnstudy() {
    pageFnlernen();
}

function pageFnkartensatz() {
//	$(".cardsets-container").each(function() {
//		loadStatistics($(this));
//	});
    loadCardsContent();
    touchFriendlyHover($("#cardset-content"), ".hover-layer");
    touchFriendlyHover($(".js-learn-options-btn"));
    if ($("#cardset-cards-content").length > 0 && $("#cardset-cards-content").hasClass('disableCopying')) {
        enableDocumentSelection(false);
    }

    if ($(".js-kind-of-list").val() != "all") {
        $(".select-filter-block").animate({
            backgroundColor: "yellow"
        }, function () {
            $(".select-filter-block").animate({
                backgroundColor: "transparent"
            });
        });
    }

    if ($(".js-show-category").val() != "") {
        $(".select-category-block").animate({
            backgroundColor: "yellow"
        }, function () {
            $(".select-category-block").animate({
                backgroundColor: "transparent"
            });
        });
    }

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    executeTour("cardset");

//    else {
//        enableDocumentSelection(true);
//    }
}

function pageFncardset() {
    pageFnkartensatz();
}

function pageFncourseCardboxes() {
    executeTour("course");
}

function pageFnkartensatzInfo() {
    touchFriendlyHover($(".js-learn-options-btn"));

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    executeTour("cardset");
}

function pageFncardsetInfo() {
    pageFnkartensatzInfo();
}

function pageFnkartensatzMitlerner() {
    touchFriendlyHover($(".js-learn-options-btn"));

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    executeTour("cardset");
}

function pageFnkartsetColearners() {
    pageFnkartensatzMitlerner();
}

function pageFnkartensatzDiskussionen() {
    touchFriendlyHover($(".js-learn-options-btn"));

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    executeTour("cardset");
}

function pageFncardsetDiscussions() {
    pageFnkartensatzDiskussionen();
}

function pageFnkartensatzEinstellungen() {
    touchFriendlyHover($(".js-learn-options-btn"));

    if ($(".donut-chart.big").length) {
        createDonutChart($(".donut-chart.big"));
    }

    executeTour("cardset");
}


function pageFncardsetSettings() {
    pageFnkartensatzEinstellungen();
}

function pageFnkartensatzKarte() {
    touchFriendlyHover($(".js-learn-options-btn"));
    equalizeCardTopTextHeight($(".view-card"));
    if ($(".question-text").length > 0 && $(".question-text").hasClass('disableCopying')) {
        enableDocumentSelection(false);
    }

    $('.card img').click(function () {
        var loc = $(this).attr("src");
        debug("Opening location in new tab or window: " + loc);
        window.open(loc, '_blank');
    });
    
    $('.sectionopener').click(function () {
        debug("Clicked sectionopener");
        var nameOfSection = $(this).data("opensection");
        $(".card #" + nameOfSection).show();
    });

//    else {
//        enableDocumentSelection(true);
//    }
}

function pageFncardsetCard() {
    pageFnkartensatzKarte();
}

function pageFnkarte() {
    pageFnkartensatzKarte();
}

function pageFncard() {
    pageFnkartensatzKarte();
}

function pageFnneuerKartensatz() {
    removeAllTinyMces();

    var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
    $("#cardset-keywords").tagsInput({
        'autocomplete_url': 'tags',
        'autocomplete': {appendTo: "#tagSuggestions"},
        'defaultText': enterKeywordsString
                // 'height': '73px'
    });

    $("#cardset-keywords_tag").on('focusout', function () {
        debug("*** Triggering enter.");
        triggerEnter("#cardset-keywords_tag");
    });

    $(".ui-autocomplete-input").css("width", "340px");

    $(".tagsinput").css("width", "100%");
    $(".tagsinput").css("height", "42px");
    $(".tagsinput").css("min-height", "42px");
    $(".tag").css("font-size", "1em");

    $(".editor-cardset-description").tinymce(editorConfig($(".editor-cardset-description")));
    $("#cardset-name").focus();

    executeTour("new-cardset");

}

function pageFnlawoffice_edit() {
    removeAllTinyMces();
    $(".graduate_areas").tinymce(editorConfig($(".graduate_areas")));
    $(".graduate_qualification").tinymce(editorConfig($(".graduate_qualification")));
    $(".graduate_offering").tinymce(editorConfig($(".graduate_offering")));
    $(".student_areas").tinymce(editorConfig($(".student_areas")));
    $(".student_offering").tinymce(editorConfig($(".student_offering")));
    $(".address").tinymce(editorConfig($(".address")));
    $(".contact").tinymce(editorConfig($(".contact")));
}

function pageFncreateDemoAccounts() {
    removeAllTinyMces();
    $(".editor-create_demo-account-message").tinymce(editorConfig($(".editor-create_demo-account-message")));
    $("#prefix").focus();
}

function pageFnnewCardset() {
    pageFnneuerKartensatz();
}

function pageFnnewDiscussion() {
    removeAllTinyMces();
    $(".editor-discussion-text").tinymce(editorConfig($(".editor-discussion-text")));
}

function pageFnaboutUs() {
    createMailtoLinks();
}

function pageFnprofile() {
    loadStatistics();
    if ($("#cards-status-development-chart").length > 0) {
        createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
    }
    if ($("#effort-chart").length > 0) {
        createEffortChart($("#effort-chart").find("canvas"));
    }
    if ($("#known-cards-chart").length > 0) {
        createKnownCardsChart($("#known-cards-chart").find("canvas"));
    }
    if ($(".donut-chart.big").length > 0) {
        createDonutChart($(".donut-chart.big"));
    }
    if ($(".bar-chart .bar").length > 0) {
        $(".bar-chart .bar").each(function () {
            $(this).animate({width: $(this).data('width') + '%'}, 1700, 'easeOutQuad');
        });
    }
    $(".donut-chart.medium").each(function (index) {
        createDonutChart($(this));
    });
    // loadColearners();
}

function pageFnsettings() {
    bindSettingsHandlers();
}

//function pageFnacademyUsers() {
//    loadUsersOfCourse();
//}

function pageFnacademyCardboxes() {
    loadBoxesOfCourse();
}

//function pageFnsearch() {
//    searchPageFormSearch();
//}

function pageFnedit_package() {
    debug("*** Page function edit package");
    removeAllTinyMces();
    $(".editor-cardset-description").tinymce(editorConfig($(".editor-cardset-description")));
    if ($(".editor-cardset-description2").length > 0) {
        $(".editor-cardset-description2").tinymce(editorConfig($(".editor-cardset-description2")));
    }
    if ($(".editor-cardset-description3").length > 0) {
        $(".editor-cardset-description3").tinymce(editorConfig($(".editor-cardset-description3")));
    }
    if ($(".editor-cardset-description4").length > 0) {
        $(".editor-cardset-description4").tinymce(editorConfig($(".editor-cardset-description4")));
    }
}