/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

function createCourseLeader() {
    var courseName = $("#nameOfNewCourse").val();
    var adminName = $("#nameOfAdmin").val();
    var proMonths = $("#proMonths").val();
    flashModal(i18n.gettext("Creating course..."));
    $.ajax({
        type: "POST",
        url: "academy-leaders",
        data: "createCourse=" + courseName + "&adminName=" + adminName + "&proMonths=" + proMonths,
        success: function (data) {
            flashModal(data);
            loadPageToCenter('academy-leaders');
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the username."));
        }
    });

}

function assignAcademyBox() {
    var addon = '';
    if ($("#academyAssignBoxes").serialize() != 'undefined') {
        addon = "&" + $("#academyAssignBoxes").serialize();
    }
    $('#center').fadeTo(200, 0.75, 'swing');
    flashModal(i18n.gettext("Spreading cardsets..."));
    $.ajax({
        type: "POST",
        url: "academy-cardboxes",
        data: "do=assignAcademyBox" + addon,
        success: function (data) {
            $('#academyCardboxesMessages').html(data);
            $("html, body").animate({ scrollTop: 0 }, "slow");
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the form data."));
        }, complete: function () {
            $('#center').fadeTo(200, 1, 'swing');
        }
    });
}

function editMailHeaderBtnClick() {
    removeAllTinyMces();
    $("#mailHeader").tinymce(editorConfig($("#mailHeader")));
    $(".edit-mailHeader-btn").hide();
    $(".save-edit-mailHeader-btn").show();
    $(".cancel-edit-mailHeader-btn").show();
}

function mailHeaderFocus() {
    $(".edit-mailHeader-btn").hide();
    $(".save-edit-mailHeader-btn").show();
    $(".cancel-edit-mailHeader-btn").show();
}

function saveMailHeaderBtnClick() {
    $(".save-edit-mailHeader-btn").hide();
    $(".cancel-edit-mailHeader-btn").hide();
    $(".edit-mailHeader-btn").show();

    var mailHeader = encodeURIComponent($('#mailHeader').html());

    $('#mailHeader').fadeTo(400, 0.3);

    removeAllTinyMces();
    flashModal(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: "academy-mails",
        data: "mailHeader=" + mailHeader,
        context: this,
        success: function (data) {
            $('#mailHeader').fadeTo(400, 1.0);
            flashModal(data);
        }
    });

}

function cancelMailHeaderBtnClick() {
    $(".cancel-edit-mailHeader-btn").hide();
    $(".save-edit-mailHeader-btn").hide();
    $(".edit-cardset-mailHeader-btn").show();
    removeAllTinyMces();
}

function editMailFooterBtnClick() {
    removeAllTinyMces();
    $("#mailFooter").tinymce(editorConfig($("#mailFooter")));
    $(".edit-mailFooter-btn").hide();
    $(".save-edit-mailFooter-btn").show();
    $(".cancel-edit-mailFooter-btn").show();
}

function mailFooterFocus() {
    $(".edit-mailFooter-btn").hide();
    $(".save-edit-mailFooter-btn").show();
    $(".cancel-edit-mailFooter-btn").show();
}

function saveMailFooterBtnClick() {
    $(".save-edit-mailFooter-btn").hide();
    $(".cancel-edit-mailFooter-btn").hide();
    $(".edit-mailFooter-btn").show();

    var mailFooter = encodeURIComponent($('#mailFooter').html());

    $('#mailFooter').fadeTo(400, 0.3);

    removeAllTinyMces();
    flashModal(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: "academy-mails",
        data: "mailFooter=" + mailFooter,
        context: this,
        success: function (data) {
            $('#mailFooter').fadeTo(400, 1.0);
            flashModal(data);
        }
    });

}

function cancelMailFooterBtnClick() {
    $(".cancel-edit-mailFooter-btn").hide();
    $(".save-edit-mailFooter-btn").hide();
    $(".edit-cardset-mailFooter-btn").show();
    removeAllTinyMces();
}

function editMailUserDataBtnClick() {
    removeAllTinyMces();
    $("#mailUserData").tinymce(editorConfig($("#mailUserData")));
    $(".edit-mailUserData-btn").hide();
    $(".save-edit-mailUserData-btn").show();
    $(".cancel-edit-mailUserData-btn").show();
}

function mailUserDataFocus() {
    $(".edit-mailUserData-btn").hide();
    $(".save-edit-mailUserData-btn").show();
    $(".cancel-edit-mailUserData-btn").show();
}

function saveMailUserDataBtnClick() {
    $(".save-edit-mailUserData-btn").hide();
    $(".cancel-edit-mailUserData-btn").hide();
    $(".edit-mailUserData-btn").show();

    var mailUserData = encodeURIComponent($('#mailUserData').html());

    $('#mailUserData').fadeTo(400, 0.3);

    removeAllTinyMces();
    flashModal(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: "academy-mails",
        data: "mailUserData=" + mailUserData,
        context: this,
        success: function (data) {
            $('#mailUserData').fadeTo(400, 1.0);
            flashModal(data);
        }
    });
}

function saveMailUserDataSubjectBtnClick() {
    var mailUserDataSubject = encodeURIComponent($('#mailUserDataSubject').val());

    $('#mailUserDataSubject').fadeTo(400, 0.3);

    removeAllTinyMces();
    flashModal(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: "academy-mails",
        data: "mailUserDataSubject=" + mailUserDataSubject,
        context: this,
        success: function (data) {
            $('#mailUserDataSubject').fadeTo(400, 1.0);
            flashModal(data);
        }
    });
}

function cancelMailUserDataBtnClick() {
    $(".cancel-edit-mailUserData-btn").hide();
    $(".save-edit-mailUserData-btn").hide();
    $(".edit-cardset-mailUserData-btn").show();
    removeAllTinyMces();
}

function saveAcademyMail() {
    var mailSenderName = $("#mailSenderName").val();
    var mailSenderAddress = $("#mailSenderAddress").val();
    flashModal(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: "academy-configuration",
        data: "mailSenderName=" + mailSenderName + "&mailSenderAddress=" + mailSenderAddress,
        context: this,
        success: function (data) {
            $('mailFooter').fadeTo(400, 1.0);
            flashModal(data);
        }
    });
}

function setCourseOption(el, refresh) {
    var option = $(el).attr('id');
    var type= $(el).prop('tagName');
    var value;
    if (type.toLowerCase() == 'select') {
        value = $(el).val();
    } else {
        value = $(el).is(':checked');
    }
    var url = $('#pagehash').val();
    shortInfo(i18n.gettext("Saving..."));
    $.ajax({
        type: "POST",
        url: url,
        data: "option=" + option + "&value=" + value,
        context: this,
        success: function (data) {
            $(".shortInfo").slideUp();
            var obj = JSON.parse(data);
            if (obj.success) {
                if (option == 'hideShop' || option == 'hideProPurchase') {
                    loadPageToCenter('academy-settings');
                    flashModal(obj.message);
                } else {
                    if (refresh === true) {
                        refreshPage();
                        flashModal(obj.message);
                    } else {
                        shortInfo(obj.message);
                    }
                }
            } else {
                if (url.startsWith("academy-settings")) {
                    // var message = i18n.gettext("");
                    proCoursesInfoModal(obj.message);
                    // generalPurposeModal(obj.message, i18n.gettext("Upgrade to a course tariff"));
                } else if (option == "enableRanking") {
                    proTeacherInfoModal(sprintf(i18n.gettext("Upgrade to %s for Teachers to enable the ranking module!"), $('html').data('apptitle')));
                } else if (option == "disableForum") {
                    proTeacherInfoModal(sprintf(i18n.gettext("Upgrade to %s for Teachers to remove the forum module!"), $('html').data('apptitle')));
                } else {
                    flashModal(obj.message);
                }
                if (type.toLowerCase() == 'select') {
                    //TODO how do we revert the selection to the before-value?
                } else {
                    $(el).is(':checked');
                    if (value == true) {
                        $(el).prop('checked', false);
                    } else {
                        $(el).prop('checked', true);
                    }
                }
            }
        }
    });
}

//function setCourseOption(el) {
//    var option = $(el).attr('id');
//    var type= $(el).prop('tagName');
//    var value;
//    if (type.toLowerCase() == 'select') {
//        value = $(el).val();
//    } else {
//        value = $(el).is(':checked');
//    }
//    var url = $('#pagehash').val();
//    shortInfo(i18n.gettext("Saving..."));
//    $.ajax({
//        type: "POST",
//        url: url,
//        data: "option=" + option + "&value=" + value,
//        context: this,
//        success: function (data) {
//            shortInfo(data);
//        }
//    });
//}

function academyAddProMonths(elem) {
    var userId = $(elem).data('userid');
    var proMonths = $(elem).val();
    if (confirm(i18n.gettext('Add PRO months (chargeable)?'))) {
        $('#center').fadeTo(300, 0.75, 'swing');
        flashModal(i18n.gettext("Please wait..."), 10000);
        $.ajax({
            type: "POST",
            url: "academy-leaders",
            data: "addProMonths=" + proMonths + "&userId=" + userId,
            success: function (data) {
                flashModal(data);
                var url = $('#pagehash').val();
                loadPageToCenter(url);
            }, complete: function () {
                $('#center').fadeTo(300, 1, 'swing');
            }, error: function (xhr, status, text) {
                alert(i18n.gettext("Possibly failed to add the PRO-months. Please check!"));
                refreshPage();
            }
        });
    }
}

function showCoursesAndBoxesOf(elem) {
    var userId = $(elem).data('userid');
    $("#coursesAndBoxes-" + userId).toggle();
    if ($("#coursesAndBoxes-" + userId).is(":visible")) {
        $.ajax({
            type: "POST",
            url: "academy-leaders",
            data: "showCoursesAndBoxesOf=" + userId + "&module=coursesAndBoxesOfUser",
            success: function (data) {
                $("#coursesAndBoxes-" + userId).html(data);
            }
        });
    }
}

function createAcademy() {
    var academyName = $("#academyName").val();
    var academyCity = $("#academyCity").val();
    $.ajax({
        type: "POST",
        url: "academy-leaders",
        data: "createAcademyName=" + academyName + "&createAcademyCity=" + academyCity,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            if (obj.success) {
                loadPageByAjax('academy-leaders');
            }
        },
        error: function () {
            flashModal(i18n.gettext("Error while submitting the form data."));
        }
    });
}

function academyNameBlur() {
    $(document).off("keypress.editheadline");
    $(".edit-cardset-title-btn").show();
    $(".page-headline.input").attr("contenteditable", false);
    // var academyId = $("#academyid").val();
    var value = encodeURIComponent($(".page-headline.input").html());
    $.ajax({
        type: "POST",
        url: "academy-settings",
        data: "setAcademyName=" + value,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function updateProCoursesInfoSentence() {
    $.ajax({
        type: "GET",
        url: "profile?proInfoSentence=1",
        data: "dynReq=1",
    }).done(function (data) {
        $("#pro-courses-info-modal .pro-info-sentence").html(data);
    });
}

// open pro-info modal button
function proCoursesInfoModal(message) {
    $("#pro-courses-info-modal").modal();
    $("#pro-courses-info-modal .pro-info-sentence").html(message);
}

// open pro-info modal button
function proTeacherInfoModal(message) {
    $("#pro-teacher-info-modal").modal();
    $("#pro-teacher-info-modal .pro-info-sentence").html(message);
}