/* MULTIPLE NEW CARDS MODAL */

// update cookie when changing the question type
function newCardQuestionTypeChange() {
    var questionType = $('#multiple-new-cards-modal input[name=question-type]:checked').val();
    setCookie('newCardQuestionType', questionType, 9999);
}

function checkNumOfNewCardsPossible(boxId) {
    $.ajax({
        type: "GET",
        url: "kartensatz-karten-" + boxId,
        data: "getCreateCardInfo=1",
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());

            var numOfCreatedCards = parseInt(obj.numOfCreatedCards);
            var numOfCardsPerBox = parseInt(obj.numOfCardsPerBox);
            var proUser = obj.proUser;

            var maxCardsLeft = numOfCardsPerBox - numOfCreatedCards;

            var selectedNumOfNewCards = $("#multiple-new-cards-modal .js-card-amount").val();

            if ((selectedNumOfNewCards > maxCardsLeft) && !proUser) {
                $("#multiple-new-cards-modal #limit-message").html(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
                        + ' '
                        + i18n.gettext('Cards in trash are counted as well.')
                        + ' '
                        + '<a href="upgrade" style="font-weight: bold;">UPGRADE</a>');
//                    repeticoPromptModal(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
//                            + '<br/><br/>'
//                            + sprintf(i18n.gettext('Create up to 2000 cards per box with %s!'), '<a href="upgrade" style="font-weight:bold;" class="bright-link click">' + 'Repetico' + ' PRO</a>'), 6000);
                $("#multiple-new-cards-modal .action-btn").attr("disabled", "disabled");
            } else if ((selectedNumOfNewCards > maxCardsLeft) && proUser) {
                $("#multiple-new-cards-modal #limit-message").html(i18n.gettext('Maximum number of cards per cardset reached.')
                        + ' '
                        + i18n.gettext('Cards in trash are counted as well.'));
                $("#multiple-new-cards-modal .action-btn").attr("disabled", "disabled");
            } else {
                $("#multiple-new-cards-modal #limit-message").html('');
                $("#multiple-new-cards-modal .action-btn").removeAttr("disabled");
                // shortInfo(i18n.gettext('Anzahl von neuen Karten ist m&ouml;glich.'));
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function multipleNewCardsModalChangeAmount() {
    delay(function () {
        var boxId = $("#boxId").val();
        checkNumOfNewCardsPossible(boxId);
    }, 1000);
}

// open multiple new cards modal button
function multipleNewCardsModal(onlyOneCard) {
    
    if (onlyOneCard == 1) {
        $("#multiple-new-cards-modal-select-cards-amount").hide();
        $(".js-card-amount").val(1);
        $("#multiple-new-cards-modal-title").html(i18n.gettext("New card"));
        $(".multipleNewCardsAvoxaExplanation").hide();
    } else {
        $("#multiple-new-cards-modal-select-cards-amount").show();
        $("#multiple-new-cards-modal-title").html(i18n.gettext("Multiple new cards"));
        $(".multipleNewCardsAvoxaExplanation").show();
    }
    
    var boxId = $("#boxId").val();
    $("#multiple-new-cards-modal .action-btn").show();

    $.ajax({
        type: "GET",
        url: "kartensatz-karten-" + boxId,
        data: "getCreateCardInfo=1",
        success: function (data) {
            var obj = JSON.parse(data.trim());

            $("#numOfCreatedCards").val(parseInt(obj.numOfCreatedCards));
            $("#numOfCardsPerBox").val(parseInt(obj.numOfCardsPerBox));
            $("#proUser").val(obj.proUser);

            var mcOptionsCount = $.cookie('mcOptionsCount-' + boxId)
            if (mcOptionsCount !== $("#multiple-new-cards-modal .mc-options-count-input").val()) {
                $("#multiple-new-cards-modal .mc-options-count-input").val(mcOptionsCount);
            }
            $("#multiple-new-cards-modal .mc-options-count-input").attr("onchange", "multipleNewCardsModalChangeMcOptionsCount(this)");
            var multipleNewCardsModal = $("#multiple-new-cards-modal");
            if (!multipleNewCardsModal.hasClass('current')) {
                multipleNewCardsModal.modal();
            }

            // select correct question type according to cookie
            if ($.cookie('newCardQuestionType') == "mchoice") {
                $("#mc-question").prop("checked", true);
            } else {
                $("#normal-question").prop("checked", true);
            }

            // set card amount according to cookie
            if (onlyOneCard == 1) {
                $(".js-card-amount").val(1);
            } else if ($.cookie('numberOfNewCards')) {
                $(".js-card-amount").val($.cookie('numberOfNewCards'));
            }

            setTimeout(function () {
                $("#multiple-new-cards-modal .js-card-amount").focus();
            }, 0);

            // $("#multiple-new-cards-modal .js-card-amount").on("keypress", function (e) {
            //     if (e.keyCode == 13) {
            //         createMultipleCards();
            //     }
            // });

            // get box categories
            var boxId = $("#boxId").val();
            $.getJSON("kartensatz-karten-" + boxId + '?categories', function (data) {
                // reset and build categories HTML
                $("#multiple-new-cards-modal .categories").html('');
                var categoryString = '';
                $.each(data, function (index, value) {
                    // category key = value.id
                    // category name = value.name
                    categoryString += ' \
                            <div class="input-x input-c w100 manageCategory-' + value.id + '"> \
                                <span class="custom-checkbox"> \
                                    <input class="category-selector" type="checkbox" id="category-' + value.id + '-checkbox"> \
                                    <!-- <span class="box"> \
                                        <i class="fa fa-check"></i> \
                                    </span> --> \
                                </span> \
                                <input type="text" class="w100" tabindex="20"  onblur="categoryInputBlur(this)" id="editCategory-' + value.id + '" value="' + value.name + '"';
                    if (!value.removeOrEditAllowed) {
                        categoryString += ' disabled=\"disabled\"';
                    }
                    categoryString += '>';
                    if (value.removeOrEditAllowed) {
                        categoryString += '<button class="remove-btn"  onclick="modalRemoveCategory(this)" id="removeCategory-' + value.id + '"> \
                                <i class="fa fa-minus-square-o"></i> \
                            </button>';
                    }
                    categoryString += '</div>';
                }); // end each
                $("#multiple-new-cards-modal .categories").html(categoryString);
                // get categories last selected, from cookie and check the according checkboxes
                $.getJSON("kartensatz-karten-" + boxId + '?cookieCategories', function (data) {
                    data.forEach(function (element) {
                        debug(element);
                        $("#category-" + element + "-checkbox").attr("checked", '');
                    });
                });

            }); // end $.getJSON
        }
    });

    // checkNumOfNewCardsPossible(boxId);

} // end fn multipleNewCardsModal



// add category
function multipleNewCardsModalAddCategory(el) {
    $('.modal.current').find('.categories').append(' \
			<div class="input-x input-c w100"> \
				<span class="custom-checkbox"> \
					<input type="checkbox" class="category-selector" id="category-new-checkbox" checked="checked"> \
					<!-- <span class="box"> \
						<i class="fa fa-check"></i> \
					</span> --> \
				</span> \
	    		<input type="text" class="w100" tabindex="20" onblur="categoryInputBlur(this)"> \
	  			<button class="remove-btn" onclick="modalRemoveCategory(this)"> \
	  				<i class="fa fa-minus-square-o"></i> \
	  			</button> \
	  		</div> \
		');
    $(el).closest(".modal").find("input:last").focus();
    $(el).closest(".modal").find("input[type=checkbox]:last").prop("checked", true);
}

function createCardWithForcedCategory() {
    // Is at least one category selected?
    
    var categories = [];
    $("#multiple-new-cards-modal").find(".category-selector").each(function (index) {
        // folgendes ändern im Sinne von:
        // $('input:check')[0].checked == true;
        if ($(this).is(":checked")) {
            var mystr = $(this).attr('id');
            var myarr = mystr.split("-");
            var categoryId = myarr[1];
            categories.push(categoryId);
        }
    });
    
    if (categories.length == 0) {
        $("#multiple-new-cards-modal #selectAtLeastOneCategory").show();
        $("#multiple-new-cards-modal .action-btn").effect("shake");
    } else {
        $("#multiple-new-cards-modal #selectAtLeastOneCategory").hide();
        multipleNewCardsModalAction();
    }
}

function multipleNewCardsModalAction() {

    $("#multiple-new-cards-modal .action-btn").hide();

    $('#firstCardsetActions').hide();

    // erst warten, bis evtl. Kategorie neu angelegt, also das letzte onblur() ausgeführt!

    cardAmount = parseInt($("#multiple-new-cards-modal .js-card-amount").val());
    var maxNumberNewCards = 10;

    if (cardAmount > maxNumberNewCards) {
        $("#limit-message").html(sprintf(i18n.gettext("The number of cards cannot exceed %s!"), maxNumberNewCards));
        $("#multiple-new-cards-modal .action-btn").show();
    } else {

        // get selected categories
        var categories = [];
        $("#multiple-new-cards-modal").find(".category-selector").each(function (index) {
            // folgendes ändern im Sinne von:
            // $('input:check')[0].checked == true;
            if ($(this).is(":checked")) {
                var mystr = $(this).attr('id');
                var myarr = mystr.split("-");
                var categoryId = myarr[1];
                categories.push(categoryId);
            }
        });

        createMultipleCards(cardAmount, categories);

    }

    // createMultipleCards(1, []);
}



function createMultipleCards(cardAmount, categories) {

    if (cardAmount == 1) {
        flashModal(i18n.gettext("Creating card..."));
    } else {
        flashModal(i18n.gettext("Creating cards..."));
    }

    categories = categories || [];

    // update cookie card amount
    setCookie('numberOfNewCards', cardAmount, 9999);

    // create container for cards if it doesn't exist yet
    if (!$(".multiple-new-cards-container").length) {
        $(".new-card").after('<div class="multiple-new-cards-container"></div>');
    }
    var container = $(".multiple-new-cards-container");

    var module = "card";
    var cardContext = "&cardContext=creating";
    var createMultiple = "&createMultiple=" + cardAmount;
    var boxId = $(".page-top").data("box-id");

    $.ajax({
        type: "GET",
        url: "kartensatz-karten-" + boxId,
        data: "getCreateCardInfo=1",
        success: function (data) {
            // closeModal();
            var obj = JSON.parse(data.trim());

            $("#numOfCreatedCards").val(parseInt(obj.numOfCreatedCards));
            $("#numOfCardsPerBox").val(parseInt(obj.numOfCardsPerBox));
            $("#proUser").val(obj.proUser);

            debug("cardAmount: " + cardAmount);
            debug("obj.numOfCreatedCards: " + parseInt(obj.numOfCreatedCards));
            debug("obj.numOfCardsPerBox: " + parseInt(obj.numOfCardsPerBox));
            debug("createdCards + cardAmount: " + (parseInt(obj.numOfCreatedCards) + parseInt(cardAmount)));
            if ((parseInt(obj.numOfCreatedCards) + parseInt(cardAmount)) > parseInt(obj.numOfCardsPerBox)) {
                if (obj.proUser) {
                    flashModal(i18n.gettext('Maximum number of cards per cardset reached.') + ' <b>' + i18n.gettext('Cards in trash are counted as well.') + '</b>');
                } else {
                    repeticoPromptModal(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
                            + ' <b>'
                            + i18n.gettext('Cards in trash are counted as well.')
                            + '</b><br/><br/>'
                            + sprintf(i18n.gettext('Create up to 2000 cards per box with %s!'), '<span style="font-weight:bold;">' + $("html").data("apptitle") + ' PRO</span>'));
                }
            } else {
                var nonce = getNonce();
                $.ajax({
                    // retry-sensitive
                    type: "POST",
                    url: "kartensatz-karten-" + boxId,
                    data: "module=" + module + cardContext + createMultiple + "&preselectedCategories=" + categories.join(",") + "&nonce=" + nonce,
                    success: function (data) {
                        removeAllTinyMces(true);
                        closeModal();
                        $('#firstCardsetActions').hide();
                        container.html(data);
                        $(".multiple-new-cards-container").show();

                        $(".js-multiple-new-cards-btn").addClass("active");
                        $(".js-multiple-new-cards-btn").prop("disabled", true);
                        $(".new-card-btn").addClass("active");
                        $(".new-card-btn").prop("disabled", true);
                        $(".js-edit-card-btn").addClass("active");
                        $(".js-edit-card-btn").prop("disabled", true);

                        $("#cardset-content .explanation").hide();
                        // removeTinyMcesInside($(".new-card"));
                        try {
                            var newCardQuestionText = $(".new-card .question-text");
                            newCardQuestionText.tinymce(editorConfig(newCardQuestionText));
                        } catch (e) {
                            window.setTimeout(function () {
                                debug("finished waiting...");
                                removeAllTinyMces(true);
                                $(".new-card .question-text").tinymce(editorConfig($(".new-card .question-text")));
                            }, 1000);

                        }
                        try {
                            var newCardAnswerText = $(".new-card .answer-text");
                            newCardAnswerText.tinymce(editorConfig(newCardAnswerText));
                        } catch (e) {
                            $(".new-card .answer-text").tinymce(editorConfig($(".new-card .answer-text")));
                        }
                        $(".new-card .mc-option-text").each(function () {
                            $(this).tinymce(editorConfig($(this)));
                        });
                        equalizeCardTopTextHeight($(".multiple-new-cards-container").find(".card"));
                        setTimeout(function () {
                            $(".multiple-new-cards-container .question-text:first").focus();
                        }, 250);

                        $(".multiple-new-cards-container .keywords-headline").show();
                        $(".multiple-new-cards-container .keywords-edit").show();
                        $(".multiple-new-cards-container .keywords-show").hide();

                        if ($(".keywords-value").length > 0) {
                            var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
                            $(".multiple-new-cards-container .keywords-edit .keywords-value").tagsInput({
                                'autocomplete_url': 'keywords',
                                'autocomplete': {appendTo: ".multiple-new-cards-container .keywords-edit .keyword-suggestions"},
                                'defaultText': enterKeywordsString,
                                'height': '40px',
                                'width': '100%'
                            });
                            $(".ui-autocomplete-input").on('focusout', function () {
                                debug("*** Triggering enter.");
                                triggerEnter(".ui-autocomplete-input");
                            });
                            $(".ui-autocomplete-input").css("width","340px");
                        }

                    }
                }); // end ajax
            }
        }
    });
} // end fn createMultipleCards



// remove keypress event handler when modal closes
// $(document).ready(function () {
//     $('#multiple-new-cards-modal').on($.modal.CLOSE, function (event, modal) {
//         $("#multiple-new-cards-modal .js-card-amount").off("keypress");
//     });
// });



// update multiple-choice-option count cookie when input changes
function multipleNewCardsModalChangeMcOptionsCount(el) {
    var boxId = $("#boxId").val();
    var mcOptionsCount = $(el).val();
    setCookie('mcOptionsCount-' + boxId, mcOptionsCount, 9999);
}
